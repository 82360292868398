import { JSX } from 'preact';
import { useState } from 'preact/hooks';
import { actions, GAPS_IN_WARDROBE } from '../../state/store';
import { useStore } from '../../state/store-provider';
import { nanoid } from 'nanoid';
import { Todo } from '../../types';
import { styles } from '../../styles';

export function GapsInWardrobe(): JSX.Element {
    const {
        state: { gapsInWardrobe },
        dispatch,
    } = useStore();
    const [newGap, setNewGap] = useState('');

    function addGap(): void {
        dispatch(
            actions.set(GAPS_IN_WARDROBE, [
                ...gapsInWardrobe,
                {
                    id: nanoid(),
                    text: newGap,
                    isComplete: false,
                },
            ])
        );
        setNewGap('');
    }

    return (
        <>
            <h3>{'gaps in wardrobe'}</h3>
            <div style={{ display: 'flex' }}>
                <input
                    class={styles.classes['input&[type=text]']}
                    type={'text'}
                    value={newGap}
                    onInput={(event: JSX.TargetedEvent<HTMLInputElement>) =>
                        setNewGap(event.currentTarget.value)
                    }
                    onKeyPress={(
                        event: JSX.TargetedKeyboardEvent<HTMLInputElement>
                    ) => event.keyCode === 13 && addGap()}
                />
                <button onClick={addGap}>{'➕'}</button>
            </div>

            {gapsInWardrobe.map((gap: Todo) => {
                const { id, text, isComplete } = gap;

                return (
                    <div style={{ display: 'flex' }}>
                        <input
                            class={styles.classes['input[type=checkbox]']}
                            type={'checkbox'}
                            checked={isComplete}
                            onInput={(
                                event: JSX.TargetedEvent<HTMLInputElement>
                            ) =>
                                dispatch(
                                    actions.set(
                                        GAPS_IN_WARDROBE,
                                        gapsInWardrobe.map((gap: Todo) =>
                                            gap.id === id
                                                ? {
                                                      id,
                                                      text,
                                                      isComplete:
                                                          event.currentTarget
                                                              .checked,
                                                  }
                                                : gap
                                        )
                                    )
                                )
                            }
                        />
                        <label
                            style={{
                                margin: '0 10px',
                                width: '100%',
                                textDecoration: isComplete
                                    ? 'line-through'
                                    : 'initial',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {text}
                        </label>
                        <button
                            onClick={() => {
                                dispatch(
                                    actions.set(
                                        GAPS_IN_WARDROBE,
                                        gapsInWardrobe.filter(
                                            (todo: Todo) => todo.id !== id
                                        )
                                    )
                                );
                            }}
                        >
                            {'🗑️'}
                        </button>
                    </div>
                );
            })}
        </>
    );
}
