import { average } from './average';
import { hexToRGB } from './hex-to-rgb';

type Hex = string;
type Hexes = Hex[];
interface RGBData {
    rs: number[];
    gs: number[];
    bs: number[];
}

export function averageHexValues(hexes: Hexes): Hex {
    const { rs, gs, bs } = hexes.reduce(
        (acc: RGBData, hex: string) => {
            const { r, g, b } = hexToRGB(hex);

            acc.rs.push(r);
            acc.gs.push(g);
            acc.bs.push(b);

            return acc;
        },
        { rs: [], gs: [], bs: [] }
    );

    const averageR = Math.round(average(rs));
    const averageG = Math.round(average(gs));
    const averageB = Math.round(average(bs));

    const averageHex =
        '#' +
        averageR.toString(16).padStart(2, '0') +
        averageG.toString(16).padStart(2, '0') +
        averageB.toString(16).padStart(2, '0');

    return averageHex;
}
