import {
    CLASSIC,
    DRAMATIC,
    GAMINE,
    INGENUE,
    NATURAL,
    ROMANTIC,
} from '../constants/clothing-personalities';
import {
    DramaticDescriptions,
    ClassicDescriptions,
    NaturalDescriptions,
    GamineDescriptions,
    IngenueDescriptions,
    RomanticDescriptions,
} from '../components/style-view/clothing-personality-descriptions';
import { JSX } from 'preact';

export function mapClothingPersonalityToDescriptionComponent(
    clothingPersonality: string
): JSX.Element | null {
    if (clothingPersonality === DRAMATIC) {
        return <DramaticDescriptions />;
    } else if (clothingPersonality === CLASSIC) {
        return <ClassicDescriptions />;
    } else if (clothingPersonality === NATURAL) {
        return <NaturalDescriptions />;
    } else if (clothingPersonality === GAMINE) {
        return <GamineDescriptions />;
    } else if (clothingPersonality === INGENUE) {
        return <IngenueDescriptions />;
    } else if (clothingPersonality === ROMANTIC) {
        return <RomanticDescriptions />;
    } else {
        return null;
    }
}
