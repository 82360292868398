import { JSX } from 'preact';

interface Props {
    season: string;
}

export function SeasonHeader(props: Props): JSX.Element {
    const { season } = props;

    return <header style={style}>{season}</header>;
}

const style = {
    alignItems: 'center',
    backgroundColor: 'grey',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: 'var(--seasonHeaderHeight)',
    justifyContent: 'space-evenly',
};
