import { ComponentChildren } from 'preact';
import { JSX } from 'preact';

interface Props {
    children: ComponentChildren;
}

export function DisplayText(props: Props): JSX.Element {
    const { children } = props;
    return <div style={style}>{children}</div>;
}

const style = {
    color: 'white',
    textShadow: '0 0 5px black',
    fontWeight: 'bold',
    fontSize: 'xx-large',
};
