import jss from 'jss';
import preset from 'jss-preset-default';
import jssPluginGlobal from 'jss-plugin-global';

jss.setup(preset());
jss.use(jssPluginGlobal());

export const styles = jss.createStyleSheet({
    '@global': {
        ':root': {
            '--seasonHeaderHeight': '25px',
            '--seasonDescriptorsBarHeight': '25px',
            '--sourceSelectionBarHeight': '40px',
        },
        '*': {
            boxSizing: 'border-box',
        },
        html: {
            height: '100%',
            width: '100%',
        },
        body: {
            height: '100%',
            width: '100%',
            padding: 0,
            margin: 0,
            backgroundColor: '#fafafa',
            fontSize: '1.1rem',
            fontFamily: 'Arial, Helvetica, sans-serif',
            color: '#444444',
        },
        'h1, h2, h3, h4, h5, h6': {
            margin: '20px 0 10px 0',
        },
    },
    'input&[type=text]': {
        width: '100%',
        height: '30px',
        font: 'inherit',
    },
    'input[type=number]': {
        width: '100%',
        height: '30px',
        padding: 0,
        margin: 0,
        font: 'inherit',
    },
    'input[type=checkbox]': {
        height: '25px',
        width: '25px',
        flexShrink: 0,
        margin: 0,
    },
    textarea: {
        minHeight: '30px',
        margin: 0,
        padding: 0,
        fontSize: 'inherit',
        width: '100%',
        resize: 'none',
    },
    select: {
        width: '100%',
        minHeight: '30px',
        font: 'inherit',
    },
});

styles.attach();
