import { HIP, LOW_WAIST, WAIST } from '../../constants/belt-to-waist';
import {
    PROPORTIONAL,
    SMALL_BUST,
    SMALL_HIPS,
} from '../../constants/bust-to-hip';
import { SMALL_BUSTED, SMALL_HIPPED } from '../../constants/purse-guide';
import {
    actions,
    QUADRANT_1_MEASUREMENT,
    QUADRANT_2_MEASUREMENT,
    QUADRANT_3_MEASUREMENT,
    QUADRANT_4_MEASUREMENT,
    SELECTED_BELT_WAIST_RATIO,
    SELECTED_BUST_HIP_RATIO,
    SELECTED_PURSE_GUIDE,
} from '../../state/store';
import { useStore } from '../../state/store-provider';
import { JSX } from 'preact';
import { Select } from '../select';
import { styles } from '../../styles';

export function ProportionAndBalancePage(): JSX.Element {
    const {
        state: {
            selectedBustHipRatio,
            selectedBeltWaistRatio,
            selectedPurseGuide,
            quadrant1Measurement,
            quadrant2Measurement,
            quadrant3Measurement,
            quadrant4Measurement,
        },
        dispatch,
    } = useStore();

    return (
        <>
            <h3>{'proportion and balance'}</h3>
            <h6>{'bust:hip ratio'}</h6>
            <Select
                onInput={(event: JSX.TargetedEvent<HTMLSelectElement>) =>
                    dispatch(
                        actions.set(
                            SELECTED_BUST_HIP_RATIO,
                            event.currentTarget.value
                        )
                    )
                }
            >
                <option selected={true} disabled={true}></option>
                <option
                    selected={selectedBustHipRatio === SMALL_BUST}
                    value={SMALL_BUST}
                >
                    {SMALL_BUST}
                </option>
                <option
                    selected={selectedBustHipRatio === PROPORTIONAL}
                    value={PROPORTIONAL}
                >
                    {PROPORTIONAL}
                </option>
                <option
                    selected={selectedBustHipRatio === SMALL_HIPS}
                    value={SMALL_HIPS}
                >
                    {SMALL_HIPS}
                </option>
            </Select>

            <h6>{'belt:waist ratio'}</h6>
            <Select
                onInput={(event: JSX.TargetedEvent<HTMLSelectElement>) =>
                    dispatch(
                        actions.set(
                            SELECTED_BELT_WAIST_RATIO,
                            event.currentTarget.value
                        )
                    )
                }
            >
                <option selected={true} disabled={true}></option>
                <option
                    selected={selectedBeltWaistRatio === WAIST}
                    value={WAIST}
                >
                    {WAIST}
                </option>
                <option
                    selected={selectedBeltWaistRatio === LOW_WAIST}
                    value={LOW_WAIST}
                >
                    {LOW_WAIST}
                </option>
                <option selected={selectedBeltWaistRatio === HIP} value={HIP}>
                    {HIP}
                </option>
            </Select>

            <h6>{'purse guide'}</h6>
            <Select
                onInput={(event: JSX.TargetedEvent<HTMLSelectElement>) =>
                    dispatch(
                        actions.set(
                            SELECTED_PURSE_GUIDE,
                            event.currentTarget.value
                        )
                    )
                }
            >
                <option selected={true} disabled={true}></option>
                <option
                    selected={selectedPurseGuide === SMALL_BUSTED}
                    value={SMALL_BUSTED}
                >
                    {SMALL_BUSTED}
                </option>
                <option
                    selected={selectedPurseGuide === SMALL_HIPPED}
                    value={SMALL_HIPPED}
                >
                    {SMALL_HIPPED}
                </option>
            </Select>

            <h6>{'first quadrant measurement'}</h6>
            <input
                class={styles.classes['input[type=number]']}
                type="number"
                onInput={(event: JSX.TargetedEvent<HTMLInputElement>) =>
                    dispatch(
                        actions.set(
                            QUADRANT_1_MEASUREMENT,
                            event.currentTarget.value
                        )
                    )
                }
                value={quadrant1Measurement}
            />
            <h6>{'second quadrant measurement'}</h6>
            <input
                class={styles.classes['input[type=number]']}
                type="number"
                onInput={(event: JSX.TargetedEvent<HTMLInputElement>) =>
                    dispatch(
                        actions.set(
                            QUADRANT_2_MEASUREMENT,
                            event.currentTarget.value
                        )
                    )
                }
                value={quadrant2Measurement}
            />
            <h6>{'third quadrant measurement'}</h6>
            <input
                class={styles.classes['input[type=number]']}
                type="number"
                onInput={(event: JSX.TargetedEvent<HTMLInputElement>) =>
                    dispatch(
                        actions.set(
                            QUADRANT_3_MEASUREMENT,
                            event.currentTarget.value
                        )
                    )
                }
                value={quadrant3Measurement}
            />
            <h6>{'fourth quadrant measurement'}</h6>
            <input
                class={styles.classes['input[type=number]']}
                type="number"
                onInput={(event: JSX.TargetedEvent<HTMLInputElement>) =>
                    dispatch(
                        actions.set(
                            QUADRANT_4_MEASUREMENT,
                            event.currentTarget.value
                        )
                    )
                }
                value={quadrant4Measurement}
            />
        </>
    );
}
