import { getColorDataByColorId } from '../utilities/kettlewell';
import { DisplayText } from './display-text';
import { JSX } from 'preact';

interface Props {
    season: string;
    colorId: string;
}

export function KettlewellColor(props: Props): JSX.Element {
    const { season, colorId } = props;
    const colorData = getColorDataByColorId(colorId);
    const name = colorData?.name;
    const keyName = colorData?.keyName;

    return (
        <div
            style={{
                ...kettlewellColorStyle,
                background: `url(/assets/kettlewell/${season}/${keyName}.jpg) no-repeat center/cover`,
            }}
        >
            <DisplayText>{name}</DisplayText>
        </div>
    );
}

const kettlewellColorStyle = {
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
