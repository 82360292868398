import { JSX } from 'preact';
import { styles } from '../styles';

type Props = JSX.HTMLAttributes<HTMLSelectElement>;

export function Select(props: Props): JSX.Element {
    const { class: givenClass, ...rest } = props;
    return (
        <select
            class={[styles.classes.select, givenClass].join(' ')}
            {...rest}
        />
    );
}
