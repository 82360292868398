import { BodyGeometricsPage } from './body-geometrics-page';
import { GapsInWardrobe } from './gaps-in-wardrobe';
import { ProportionAndBalancePage } from './proportion-and-balance-page';
import { YinAndYangPage } from './yin-and-yang-page';
import { JSX } from 'preact';

export function StyleView(): JSX.Element {
    return (
        <div style={{ margin: '10px' }}>
            <BodyGeometricsPage />
            <ProportionAndBalancePage />
            <YinAndYangPage />
            <GapsInWardrobe />
            <hr />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {'❤️'}
            </div>
        </div>
    );
}
