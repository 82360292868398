// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { averageHexValues } from '../../utilities/average-hex-values';
import { useStore } from '../../state/store-provider';
import { DisplayText } from '../display-text';
import { JSX } from 'preact';
import * as allColors from '../../constants/colors';
import { Color } from '../../types';

interface Props {
    season: string;
    id: string;
}

export function ColorView(props: Props): JSX.Element {
    const { id } = props;
    const {
        state: { selectedColorSource },
    } = useStore();
    const { name, [selectedColorSource]: hexes } = Object.values(
        allColors
    ).find((color: Color) => color.id === id);

    const averageHex = averageHexValues(hexes);

    return (
        <div style={colorViewStyle}>
            <div style={{ ...colorStyle, background: averageHex }}>
                <DisplayText>{name}</DisplayText>
            </div>
        </div>
    );
}

const colorViewStyle = {
    height: '100%',
};

const colorStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
