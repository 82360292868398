import { JSX } from 'preact';
import jss from 'jss';

type Props = JSX.HTMLAttributes<HTMLTextAreaElement>;

const { classes } = jss
    .createStyleSheet({
        'textarea-wrapper': {
            display: 'grid',
            '&::after': {
                content: 'attr(data-value) " "',
                whiteSpace: 'pre-wrap',
                visibility: 'hidden',
            },
            '& > textarea': {
                resize: 'none',
                overflow: 'hidden',
            },
            '&::after, & > textarea': {
                padding: '0.5rem',
                font: 'inherit',
                gridArea: '1 / 1 / 2 / 2',
            },
        },
    })
    .attach();

export function Textarea(props: Props): JSX.Element {
    return (
        <div class={classes['textarea-wrapper']} data-value={props.value}>
            <textarea {...props}></textarea>
        </div>
    );
}
