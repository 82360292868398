import { KETTLEWELL_DATA } from '../constants/kettlewell-data';
import { KettlewellColorData } from '../types';

export function getColorDataByColorId(colorId: string): KettlewellColorData {
    const colorData = KETTLEWELL_DATA.find(
        (data: KettlewellColorData) => data.keyName === colorId
    );

    return colorData!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}

export function findAllColorDataForSeason(
    season: string
): KettlewellColorData[] {
    return KETTLEWELL_DATA.filter((data: KettlewellColorData) =>
        data.seasons.includes(season)
    );
}
