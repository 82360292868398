import {
    CLASSIC,
    DRAMATIC,
    GAMINE,
    INGENUE,
    NATURAL,
    ROMANTIC,
} from '../../constants/clothing-personalities';
import { JSX } from 'preact';

export function DramaticDescriptions(): JSX.Element {
    return (
        <div style={descriptionStyles}>
            <strong>{DRAMATIC}</strong>
            <div>{'angular'}</div>
            <div>{'over-the-top'}</div>
            <div>{'bold'}</div>
            <div>{'geometric'}</div>
            <div>{'large scale'}</div>
            <div>{'exciting'}</div>
            <div>{'striking'}</div>
            <div>{'not matronly'}</div>
        </div>
    );
}

export function ClassicDescriptions(): JSX.Element {
    return (
        <div style={descriptionStyles}>
            <strong>{CLASSIC}</strong>
            <div>{'tailored'}</div>
            <div>{'expensive'}</div>
            <div>{'formal'}</div>
            <div>{'sophisticated'}</div>
            <div>{'real jewelry'}</div>
            <div>{'not messy'}</div>
        </div>
    );
}

export function NaturalDescriptions(): JSX.Element {
    return (
        <div style={descriptionStyles}>
            <strong>{NATURAL}</strong>
            <div>{'comfort'}</div>
            <div>{'unstructured'}</div>
            <div>{'natural fibers'}</div>
            <div>{'easy'}</div>
            <div>{'outdoorsy'}</div>
            <div>{'casual'}</div>
            <div>{'texture'}</div>
            <div>{'not severe'}</div>
        </div>
    );
}

export function GamineDescriptions(): JSX.Element {
    return (
        <div style={descriptionStyles}>
            <strong>{GAMINE}</strong>
            <div>{'details'}</div>
            <div>{'whimsy'}</div>
            <div>{'youthful'}</div>
            <div>{'small'}</div>
            <div>{'neat'}</div>
            <div>{'chic'}</div>
            <div>{'fun'}</div>
            <div>{'not swamped'}</div>
        </div>
    );
}

export function IngenueDescriptions(): JSX.Element {
    return (
        <div style={descriptionStyles}>
            <strong>{INGENUE}</strong>
            <div>{'princess'}</div>
            <div>{'fill'}</div>
            <div>{'sparkle'}</div>
            <div>{'lace'}</div>
            <div>{'small pieces'}</div>
            <div>{'delicate'}</div>
            <div>{'not frumpy'}</div>
        </div>
    );
}

export function RomanticDescriptions(): JSX.Element {
    return (
        <div style={descriptionStyles}>
            <strong>{ROMANTIC}</strong>
            <div>{'luxurious'}</div>
            <div>{'over-the-top'}</div>
            <div>{'glitz and glam'}</div>
            <div>{'large floral'}</div>
            <div>{'large scale'}</div>
            <div>{'cleavage'}</div>
            <div>{'sexy'}</div>
            <div>{'not matronly'}</div>
        </div>
    );
}

const descriptionStyles = {
    display: 'inline-block',
    margin: '10px',
};
