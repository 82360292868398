// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useReducer, useEffect, Reducer } from 'preact/hooks';
import { getInitialState } from './store';
import { STORAGE_KEY } from '../constants/storage';
import has from 'lodash.has';
import { State, Dispatch } from '../types';

function init(): State {
    const jsonState = localStorage.getItem(STORAGE_KEY);

    if (jsonState) {
        try {
            const initialState = getInitialState();
            const storedState = JSON.parse(jsonState);

            return Object.keys(initialState).reduce(
                (state: State, key: string) => {
                    if (has(storedState, key)) {
                        state[key] = storedState[key];
                    } else {
                        state[key] = initialState[key];
                    }

                    return state;
                },
                {}
            );
        } catch (error) {
            return getInitialState();
        }
    } else {
        return getInitialState();
    }
}

export function usePersistedReducer(reducer: Reducer): [State, Dispatch] {
    const [state, dispatch] = useReducer(reducer, getInitialState(), init);

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }, [state]);

    return [state, dispatch];
}
