import { getDescriptorsBySeason } from '../../utilities/get-descriptors-by-season';
import { JSX } from 'preact';

interface Props {
    season: string;
}

export function SeasonDescriptorsBar(props: Props): JSX.Element {
    const { season } = props;
    const descriptors = getDescriptorsBySeason(season);

    return (
        <header style={style}>
            {descriptors.map((descriptor: string) => (
                <div>{descriptor}</div>
            ))}
        </header>
    );
}

const style = {
    alignItems: 'center',
    backgroundColor: 'grey',
    color: 'white',
    display: 'flex',
    flexWrap: 'wrap',
    height: 'var(--seasonDescriptorsBarHeight)',
    justifyContent: 'space-around',
};
