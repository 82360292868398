import { JSX } from 'preact';
import {
    DRAMATIC,
    CLASSIC,
    NATURAL,
    GAMINE,
    INGENUE,
    ROMANTIC,
} from '../../constants/clothing-personalities';
import {
    actions,
    SELECTED_CLOTHING_PERSONALITIES,
    KEY_WORDS,
    STRUCTURE,
    HAIR,
    ACCESSORIES_AND_SCALE,
    JACKETS_COATS_LAPELS,
    SKIRTS_PANTS_BOTTOMS,
    DRESSES_JUMPSUITS,
    PATTERS,
    FABRICS,
    AVOID,
    STYLE_ICONS,
} from '../../state/store';
import { useStore } from '../../state/store-provider';
import { mapClothingPersonalityToDescriptionComponent } from '../../utilities/map-clothing-personality-to-description-component';
import { Select } from '../select';
import { Textarea } from '../textarea';

export function YinAndYangPage(): JSX.Element {
    const {
        state: {
            selectedClothingPersonalities,
            keyWords,
            structure,
            hair,
            accessoriesAndScale,
            jacketsCoatsLapels,
            skirtsPantsBottoms,
            dressesJumpsuits,
            patterns,
            fabrics,
            avoid,
            styleIcons,
        },
        dispatch,
    } = useStore();

    return (
        <>
            <h3>{'yin and yang'}</h3>

            <h6>{'clothing personalities'}</h6>
            <Select
                multiple={true}
                onInput={(event: JSX.TargetedEvent<HTMLSelectElement>) => {
                    dispatch(
                        actions.set(
                            SELECTED_CLOTHING_PERSONALITIES,
                            Array.from(event.currentTarget.selectedOptions).map(
                                (option: HTMLOptionElement) => option.value
                            )
                        )
                    );
                }}
            >
                <option
                    selected={selectedClothingPersonalities.includes(DRAMATIC)}
                    value={DRAMATIC}
                >
                    {DRAMATIC}
                </option>
                <option
                    selected={selectedClothingPersonalities.includes(CLASSIC)}
                    value={CLASSIC}
                >
                    {CLASSIC}
                </option>
                <option
                    selected={selectedClothingPersonalities.includes(NATURAL)}
                    value={NATURAL}
                >
                    {NATURAL}
                </option>
                <option
                    selected={selectedClothingPersonalities.includes(GAMINE)}
                    value={GAMINE}
                >
                    {GAMINE}
                </option>
                <option
                    selected={selectedClothingPersonalities.includes(INGENUE)}
                    value={INGENUE}
                >
                    {INGENUE}
                </option>
                <option
                    selected={selectedClothingPersonalities.includes(ROMANTIC)}
                    value={ROMANTIC}
                >
                    {ROMANTIC}
                </option>
            </Select>

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly',
                }}
            >
                {selectedClothingPersonalities.map(
                    (selectedClothingPersonality: string) =>
                        mapClothingPersonalityToDescriptionComponent(
                            selectedClothingPersonality
                        )
                )}
            </div>

            <h6>{'key words'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(actions.set(KEY_WORDS, event.currentTarget.value))
                }
                placeholder={'notes'}
                value={keyWords}
            />
            <h6>{'structure'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(actions.set(STRUCTURE, event.currentTarget.value))
                }
                placeholder={'notes'}
                value={structure}
            />
            <h6>{'hair'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(actions.set(HAIR, event.currentTarget.value))
                }
                placeholder={'notes'}
                value={hair}
            />
            <h6>{'accessories and scale'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(
                        actions.set(
                            ACCESSORIES_AND_SCALE,
                            event.currentTarget.value
                        )
                    )
                }
                placeholder={'notes'}
                value={accessoriesAndScale}
            />
            <h6>{'jackets/coats/lapels'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(
                        actions.set(
                            JACKETS_COATS_LAPELS,
                            event.currentTarget.value
                        )
                    )
                }
                placeholder={'notes'}
                value={jacketsCoatsLapels}
            />
            <h6>{'skirts/pants/other bottoms'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(
                        actions.set(
                            SKIRTS_PANTS_BOTTOMS,
                            event.currentTarget.value
                        )
                    )
                }
                placeholder={'notes'}
                value={skirtsPantsBottoms}
            />
            <h6>{'dresses and jumpsuits'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(
                        actions.set(
                            DRESSES_JUMPSUITS,
                            event.currentTarget.value
                        )
                    )
                }
                placeholder={'notes'}
                value={dressesJumpsuits}
            />
            <h6>{'patterns'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(actions.set(PATTERS, event.currentTarget.value))
                }
                placeholder={'notes'}
                value={patterns}
            />
            <h6>{'fabrics'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(actions.set(FABRICS, event.currentTarget.value))
                }
                placeholder={'notes'}
                value={fabrics}
            />
            <h6>{'avoid'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(actions.set(AVOID, event.currentTarget.value))
                }
                placeholder={'notes'}
                value={avoid}
            />
            <h6>{'style icons'}</h6>
            <Textarea
                onInput={(event: JSX.TargetedEvent<HTMLTextAreaElement>) =>
                    dispatch(
                        actions.set(STYLE_ICONS, event.currentTarget.value)
                    )
                }
                placeholder={'notes'}
                value={styleIcons}
            />
        </>
    );
}
