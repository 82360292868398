import { findAllColorDataForSeason } from '../../utilities/kettlewell';
import { SourceSelectionBar } from './source-selection-bar';
import { route } from 'preact-router';
import {
    PARAM_KETTLEWELL_COLOR_ID,
    PARAM_SEASON,
    ROUTE_KETTLEWELL_COLOR,
} from '../../constants/routes';
import { SeasonHeader } from './season-header';
import { SeasonDescriptorsBar } from './season-descriptors-bar';
import { JSX } from 'preact';
import { KettlewellColorData } from '../../types';

interface Props {
    season: string;
}

export function KettlewellSeason(props: Props): JSX.Element {
    const { season } = props;
    const colorData = findAllColorDataForSeason(season);

    return (
        <div style={kettlewellSeasonStyle}>
            <SeasonHeader season={season} />
            <SeasonDescriptorsBar season={season} />
            <SourceSelectionBar />
            <div style={colorsStyle}>
                {colorData.map((item: KettlewellColorData) => (
                    <img
                        style={colorStyle}
                        src={`/assets/kettlewell/${season}/${item.keyName}.jpg`}
                        onClick={() =>
                            route(
                                ROUTE_KETTLEWELL_COLOR.replace(
                                    PARAM_SEASON,
                                    season
                                ).replace(
                                    PARAM_KETTLEWELL_COLOR_ID,
                                    item.keyName
                                )
                            )
                        }
                    />
                ))}
            </div>
        </div>
    );
}

const kettlewellSeasonStyle = {
    height: '100%',
};

const colorsStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    height: `calc(100% - var(--seasonHeaderHeight) - var(--seasonDescriptorsBarHeight) - var(--sourceSelectionBarHeight))`,
};

const colorStyle = {
    width: 0,
    flex: '1 1 50px',
};
