import { useContext } from 'preact/hooks';
import { ComponentChildren, createContext, JSX } from 'preact';
import { reducer } from './store';
import { usePersistedReducer } from './use-persisted-reducer';
import { StoreContext } from '../types';

const storeContext = createContext<StoreContext>(
    null as unknown as StoreContext
);

interface Props {
    children: ComponentChildren;
}

export function StoreProvider(props: Props): JSX.Element {
    const { children } = props;
    const [state, dispatch] = usePersistedReducer(reducer);

    return (
        <storeContext.Provider value={{ state, dispatch }}>
            {children}
        </storeContext.Provider>
    );
}

export function useStore(): StoreContext {
    return useContext(storeContext);
}
