import { ComponentChildren, JSX } from 'preact';
import { useState } from 'preact/hooks';
import { useStore } from '../state/store-provider';
import { DisplayText } from './display-text';
import { MY_COLORS, MY_STYLE } from '../constants/views';
import { actions, SELECTED_VIEW } from '../state/store';
import { route } from 'preact-router';
import { ROUTE_SEASONS, ROUTE_STYLE } from '../constants/routes';

interface Props {
    children: ComponentChildren;
}

export function NavigationBar(props: Props): JSX.Element {
    const { children } = props;
    const {
        state: { selectedView },
        dispatch,
    } = useStore();
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
            <nav
                style={{
                    height: '45px',
                    flexShrink: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'grey',
                    position: 'relative',
                }}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div
                    style={{
                        fontWeight: 'bold',
                        fontSize: 'xx-large',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        marginLeft: '1rem',
                    }}
                >
                    {'☰'}
                </div>
                <DisplayText>{selectedView}</DisplayText>
            </nav>
            <div
                style={{
                    height: isExpanded ? '100%' : 0,
                    transition: 'height 0.3s ease-out',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={viewOptionStyle}
                    onClick={() => {
                        dispatch(actions.set(SELECTED_VIEW, MY_COLORS));
                        route(ROUTE_SEASONS);
                        setIsExpanded(false);
                    }}
                >
                    <DisplayText>{MY_COLORS}</DisplayText>
                </div>
                <div
                    style={viewOptionStyle}
                    onClick={() => {
                        dispatch(actions.set(SELECTED_VIEW, MY_STYLE));
                        route(ROUTE_STYLE);
                        setIsExpanded(false);
                    }}
                >
                    <DisplayText>{MY_STYLE}</DisplayText>
                </div>
            </div>
            <div
                style={{
                    height: '100%',
                    overflow: isExpanded ? 'hidden' : 'unset',
                }}
            >
                {children}
            </div>
        </div>
    );
}

const viewOptionStyle = {
    boxShadow: '0 0 2px 1px white',
    flexGrow: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
