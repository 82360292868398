import {
    SOURCE_COLOR_MUSE_FABRIC,
    SOURCE_KETTLEWELL,
} from '../../constants/color-sources';
import { actions, SELECTED_COLOR_SOURCE } from '../../state/store';
import { useStore } from '../../state/store-provider';
import { JSX } from 'preact';

export function SourceSelectionBar(): JSX.Element {
    const {
        state: { selectedColorSource },
        dispatch,
    } = useStore();

    return (
        <div style={sourceSelectionBarStyle}>
            <button
                style={buttonStyle}
                onClick={() =>
                    dispatch(
                        actions.set(
                            SELECTED_COLOR_SOURCE,
                            SOURCE_COLOR_MUSE_FABRIC
                        )
                    )
                }
            >
                <input
                    type={'checkbox'}
                    checked={selectedColorSource === SOURCE_COLOR_MUSE_FABRIC}
                />
                <label>{'Fabric'}</label>
            </button>
            <button
                style={buttonStyle}
                onClick={() =>
                    dispatch(
                        actions.set(SELECTED_COLOR_SOURCE, SOURCE_KETTLEWELL)
                    )
                }
            >
                <input
                    type={'checkbox'}
                    checked={selectedColorSource === SOURCE_KETTLEWELL}
                />
                <label>{'Kettlewell'}</label>
            </button>
        </div>
    );
}

const sourceSelectionBarStyle = {
    backgroundColor: 'gray',
    display: 'flex',
    height: 'var(--sourceSelectionBarHeight)',
    justifyContent: 'space-evenly',
};

const buttonStyle = {
    width: '100%',
};
