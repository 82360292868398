export const PARAM_SEASON = ':season';
export const PARAM_COLOR_ID = ':id';
export const PARAM_KETTLEWELL_COLOR_ID = ':colorId';

export const ROUTE_HOME = '/';
export const ROUTE_SEASONS = '/seasons';
export const ROUTE_SEASON = `/seasons/${PARAM_SEASON}/colors`;
export const ROUTE_COLOR = `/seasons/${PARAM_SEASON}/colors/${PARAM_COLOR_ID}`;

export const ROUTE_KETTLEWELL_COLOR = `/seasons/${PARAM_SEASON}/kettlewell-colors/${PARAM_KETTLEWELL_COLOR_ID}`;

export const ROUTE_STYLE = '/style';
