// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { route } from 'preact-router';
import { SOURCE_KETTLEWELL } from '../../constants/color-sources';
import { useStore } from '../../state/store-provider';
import { averageHexValues } from '../../utilities/average-hex-values';
import { KettlewellSeason } from './kettlewell-season';
import { SeasonDescriptorsBar } from './season-descriptors-bar';
import { SeasonHeader } from './season-header';
import { SourceSelectionBar } from './source-selection-bar';
import {
    PARAM_COLOR_ID,
    PARAM_SEASON,
    ROUTE_COLOR,
} from '../../constants/routes';
import { JSX } from 'preact';
import { Color } from '../../types';
import * as allColors from '../../constants/colors';

interface Props {
    season: string;
}

export function SeasonView(props: Props): JSX.Element {
    const { season } = props;
    const {
        state: { selectedColorSource },
    } = useStore();
    const seasonColors = Object.values(allColors).filter((color: Color) =>
        color.seasons.includes(season)
    );

    return selectedColorSource === SOURCE_KETTLEWELL ? (
        <KettlewellSeason season={season} />
    ) : (
        <div style={seasonViewStyle}>
            <SeasonHeader season={season} />
            <SeasonDescriptorsBar season={season} />
            <SourceSelectionBar />
            <div style={colorsStyle}>
                {seasonColors.map((color: Color) => (
                    <div
                        style={{
                            flex: '15%',
                            background: averageHexValues(
                                color[selectedColorSource]
                            ),
                        }}
                        onClick={() =>
                            route(
                                ROUTE_COLOR.replace(
                                    PARAM_SEASON,
                                    season
                                ).replace(PARAM_COLOR_ID, color.id)
                            )
                        }
                    />
                ))}
            </div>
        </div>
    );
}

const seasonViewStyle = {
    height: '100%',
    textAlign: 'center',
};

const colorsStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    height: `calc(100% - var(--seasonHeaderHeight) - var(--sourceSelectionBarHeight))`,
    justifyContent: 'center',
};
