export const WINTER_DESCRIPTORS = [
    'cool',
    'bright',
    'vivid',
    'icy',
    'high contrast',
];

export const SPRING_DESCRIPTORS = [
    'clear',
    'bright',
    'warm',
    'light',
    'splashy',
];

export const SUMMER_DESCRIPTORS = ['soft', 'blue', 'smoky', 'rose'];

export const AUTUMN_DESCRIPTORS = ['rich', 'warm', 'earthy', 'vibrant'];
