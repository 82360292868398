export const DIAMOND = 'diamond';
export const TRIANGLE = 'triangle';
export const SQUARE = 'square';
export const RECTANGLE = 'rectangle';
export const OBLONG = 'oblong';
export const HEART = 'heart';
export const OVAL = 'oval';
export const ROUND = 'round';
export const PEAR = 'pear';
export const SOFT_SQUARE = 'soft square';
