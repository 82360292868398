import { SOURCE_COLOR_MUSE_FABRIC } from '../constants/color-sources';
import { MY_COLORS } from '../constants/views';
import { Action, State } from '../types';

export const SELECTED_VIEW = 'selectedView';
export const SELECTED_COLOR_SOURCE = 'selectedColorSource';
export const SELECTED_BODY_GEOMETRY = 'selectedBodyGeometry';
export const SELECTED_FACE_SHAPES = 'selectedFaceShapes';
export const SELECTED_JAW_ANGLE = 'selectedJawAngle';
export const SELECTED_NECK_LENGTH = 'selectedNeckLength';
export const SELECTED_BUST_HIP_RATIO = 'selectedBustHipRatio';
export const SELECTED_BELT_WAIST_RATIO = 'selectedBeltWaistRatio';
export const SELECTED_PURSE_GUIDE = 'selectedPurseGuide';
export const SELECTED_CLOTHING_PERSONALITIES = 'selectedClothingPersonalities';
export const QUADRANT_1_MEASUREMENT = 'quadrant1Measurement';
export const QUADRANT_2_MEASUREMENT = 'quadrant2Measurement';
export const QUADRANT_3_MEASUREMENT = 'quadrant3Measurement';
export const QUADRANT_4_MEASUREMENT = 'quadrant4Measurement';
export const KEY_WORDS = 'keyWords';
export const STRUCTURE = 'structure';
export const HAIR = 'hair';
export const ACCESSORIES_AND_SCALE = 'accessoriesAndScale';
export const JACKETS_COATS_LAPELS = 'jacketsCoatsLapels';
export const SKIRTS_PANTS_BOTTOMS = 'skirtsPantsBottoms';
export const DRESSES_JUMPSUITS = 'dressesJumpsuits';
export const PATTERS = 'patterns';
export const FABRICS = 'fabrics';
export const AVOID = 'avoid';
export const STYLE_ICONS = 'styleIcons';
export const GAPS_IN_WARDROBE = 'gapsInWardrobe';

export function getInitialState(): State {
    return {
        [SELECTED_VIEW]: MY_COLORS,
        [SELECTED_COLOR_SOURCE]: SOURCE_COLOR_MUSE_FABRIC,
        [SELECTED_BODY_GEOMETRY]: '',
        [SELECTED_FACE_SHAPES]: [],
        [SELECTED_JAW_ANGLE]: '',
        [SELECTED_NECK_LENGTH]: '',
        [SELECTED_BUST_HIP_RATIO]: '',
        [SELECTED_BELT_WAIST_RATIO]: '',
        [SELECTED_PURSE_GUIDE]: '',
        [SELECTED_CLOTHING_PERSONALITIES]: [],
        [QUADRANT_1_MEASUREMENT]: '',
        [QUADRANT_2_MEASUREMENT]: '',
        [QUADRANT_3_MEASUREMENT]: '',
        [QUADRANT_4_MEASUREMENT]: '',
        [KEY_WORDS]: '',
        [STRUCTURE]: '',
        [HAIR]: '',
        [ACCESSORIES_AND_SCALE]: '',
        [JACKETS_COATS_LAPELS]: '',
        [SKIRTS_PANTS_BOTTOMS]: '',
        [DRESSES_JUMPSUITS]: '',
        [PATTERS]: '',
        [FABRICS]: '',
        [AVOID]: '',
        [STYLE_ICONS]: '',
        [GAPS_IN_WARDROBE]: [],
    };
}

const SET = 'SET';

export const actions = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set: (key: string, value: any): Action => ({
        type: SET,
        key,
        value,
    }),
};

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case SET:
            return {
                ...state,
                [action.key]: action.value,
            };
        default:
            throw new Error(`unhandled action: ${action}`);
    }
}
