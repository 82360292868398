// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { route } from 'preact-router';
import { WINTER, SPRING, SUMMER, AUTUMN } from '../../constants/seasons';
import { hexToRGB } from '../../utilities/hex-to-rgb';
import { averageHexValues } from '../../utilities/average-hex-values';
import { SOURCE_COLOR_MUSE_FABRIC } from '../../constants/color-sources';
import { PARAM_SEASON, ROUTE_SEASON } from '../../constants/routes';
import { DisplayText } from '../display-text';
import { JSX } from 'preact';
import {
    lagoonBlue,
    royalBlue,
    tangerine,
    poppy,
    pastelJade,
    jade,
    saffron,
    bronze,
} from '../../constants/colors';

export function SeasonsView(): JSX.Element {
    const winterLagoonBlueRGB = hexToRGB(
        averageHexValues(lagoonBlue[SOURCE_COLOR_MUSE_FABRIC])
    );
    const winterRoyalBlueRGB = hexToRGB(
        averageHexValues(royalBlue[SOURCE_COLOR_MUSE_FABRIC])
    );

    const springTangerineRGB = hexToRGB(
        averageHexValues(tangerine[SOURCE_COLOR_MUSE_FABRIC])
    );
    const springPoppyRGB = hexToRGB(
        averageHexValues(poppy[SOURCE_COLOR_MUSE_FABRIC])
    );

    const summerPastelJadeRGB = hexToRGB(
        averageHexValues(pastelJade[SOURCE_COLOR_MUSE_FABRIC])
    );
    const summerJadeRGB = hexToRGB(
        averageHexValues(jade[SOURCE_COLOR_MUSE_FABRIC])
    );

    const autumnSaffronRGB = hexToRGB(
        averageHexValues(saffron[SOURCE_COLOR_MUSE_FABRIC])
    );
    const autumnBronzeRGB = hexToRGB(
        averageHexValues(bronze[SOURCE_COLOR_MUSE_FABRIC])
    );

    return (
        <div style={seasonsViewStyle}>
            <div
                onClick={() =>
                    route(ROUTE_SEASON.replace(PARAM_SEASON, WINTER))
                }
                style={{
                    ...seasonStyle,
                    background: `linear-gradient(to bottom right, rgb(${winterLagoonBlueRGB.r}, ${winterLagoonBlueRGB.g}, ${winterLagoonBlueRGB.b}), rgb(${winterRoyalBlueRGB.r}, ${winterRoyalBlueRGB.g}, ${winterRoyalBlueRGB.b}))`,
                }}
            >
                <DisplayText>{WINTER}</DisplayText>
            </div>
            <div
                onClick={() =>
                    route(ROUTE_SEASON.replace(PARAM_SEASON, SPRING))
                }
                style={{
                    ...seasonStyle,
                    background: `linear-gradient(to bottom left, rgb(${springTangerineRGB.r}, ${springTangerineRGB.g}, ${springTangerineRGB.b}), rgb(${springPoppyRGB.r}, ${springPoppyRGB.g}, ${springPoppyRGB.b}))`,
                }}
            >
                <DisplayText>{SPRING}</DisplayText>
            </div>
            <div
                onClick={() =>
                    route(ROUTE_SEASON.replace(PARAM_SEASON, SUMMER))
                }
                style={{
                    ...seasonStyle,
                    background: `linear-gradient(to top right, rgb(${summerPastelJadeRGB.r}, ${summerPastelJadeRGB.g}, ${summerPastelJadeRGB.b}), rgb(${summerJadeRGB.r}, ${summerJadeRGB.g}, ${summerJadeRGB.b}))`,
                }}
            >
                <DisplayText>{SUMMER}</DisplayText>
            </div>
            <div
                onClick={() =>
                    route(ROUTE_SEASON.replace(PARAM_SEASON, AUTUMN))
                }
                style={{
                    ...seasonStyle,
                    background: `linear-gradient(to top left, rgb(${autumnSaffronRGB.r}, ${autumnSaffronRGB.g}, ${autumnSaffronRGB.b}), rgb(${autumnBronzeRGB.r}, ${autumnBronzeRGB.g}, ${autumnBronzeRGB.b}))`,
                }}
            >
                <DisplayText>{AUTUMN}</DisplayText>
            </div>
        </div>
    );
}

const seasonsViewStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
};

const seasonStyle = {
    alignItems: 'center',
    display: 'flex',
    flex: '50%',
    justifyContent: 'center',
};
