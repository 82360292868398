import { WINTER, SPRING, SUMMER, AUTUMN } from './seasons';

export const KETTLEWELL_DATA = [
    {
        keyName: 'primrose',
        name: 'primrose',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'soft-yellow',
        name: 'soft yellow',
        seasons: [SPRING],
    },
    {
        keyName: 'lemonade',
        name: 'lemonade',
        seasons: [SPRING],
    },
    {
        keyName: 'acid-yellow',
        name: 'acid yellow',
        seasons: [SPRING, WINTER],
    },
    {
        keyName: 'sunflower',
        name: 'sunflower',
        seasons: [AUTUMN],
    },
    {
        keyName: 'canary-yellow',
        name: 'canary yellow',
        seasons: [SPRING],
    },
    {
        keyName: 'golden-yellow',
        name: 'golden yellow',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'mimosa',
        name: 'mimosa',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'mustard',
        name: 'mustard',
        seasons: [AUTUMN],
    },
    {
        keyName: 'saffron',
        name: 'saffron',
        seasons: [AUTUMN],
    },
    {
        keyName: 'cinnamon',
        name: 'cinnamon',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'golden-spice',
        name: 'golden spice',
        seasons: [AUTUMN],
    },
    {
        keyName: 'bright-apricot',
        name: 'bright apricot',
        seasons: [SPRING],
    },
    {
        keyName: 'pale-peach',
        name: 'pale peach',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'apricot',
        name: 'apricot',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'mellow-rose',
        name: 'mellow rose',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'peach-echo',
        name: 'peach echo',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'peach-cocktail',
        name: 'peach cocktail',
        seasons: [SPRING],
    },
    {
        keyName: 'nectarine',
        name: 'nectarine',
        seasons: [SPRING],
    },
    {
        keyName: 'rosewood',
        name: 'rosewood',
        seasons: [AUTUMN],
    },
    {
        keyName: 'salmon',
        name: 'salmon',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'geranium-pink',
        name: 'geranium pink',
        seasons: [SPRING],
    },
    {
        keyName: 'pink-geranium',
        name: 'pink geranium',
        seasons: [SPRING],
    },
    {
        keyName: 'coral',
        name: 'coral',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'hot-coral',
        name: 'hot coral',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'spiced-coral',
        name: 'spiced coral',
        seasons: [AUTUMN],
    },
    {
        keyName: 'orange',
        name: 'orange',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'terracotta',
        name: 'terracotta',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'amber',
        name: 'amber',
        seasons: [AUTUMN],
    },
    {
        keyName: 'papaya',
        name: 'papaya',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'tangerine',
        name: 'tangerine',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'pumpkin',
        name: 'pumpkin',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'burnt-orange',
        name: 'burnt orange',
        seasons: [AUTUMN],
    },
    {
        keyName: 'rust',
        name: 'rust',
        seasons: [AUTUMN],
    },
    {
        keyName: 'red-earth',
        name: 'red earth',
        seasons: [AUTUMN],
    },
    {
        keyName: 'chestnut',
        name: 'chestnut',
        seasons: [AUTUMN],
    },
    {
        keyName: 'red-coral',
        name: 'red coral',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'watermelon',
        name: 'watermelon',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'red-pear',
        name: 'red pear',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'musk-red',
        name: 'musk red',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'brick-red',
        name: 'brick red',
        seasons: [AUTUMN],
    },
    {
        keyName: 'geranium',
        name: 'geranium',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'poppy',
        name: 'poppy',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'true-red',
        name: 'true red',
        seasons: [SPRING, SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'rio-red',
        name: 'rio red',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'cherry',
        name: 'cherry',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'cranberry',
        name: 'cranberry',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'carmine-red',
        name: 'carmine red',
        seasons: [WINTER],
    },
    {
        keyName: 'pink-peacock',
        name: 'pink peacock',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'ruby-red',
        name: 'ruby red',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'tango-red-silver',
        name: 'tango red & silver',
        seasons: [WINTER],
    },
    {
        keyName: 'tango-red',
        name: 'tango red',
        seasons: [WINTER],
    },
    {
        keyName: 'mars-red',
        name: 'mars red',
        seasons: [AUTUMN, WINTER],
    },
    {
        keyName: 'raspberry',
        name: 'raspberry',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'sangria',
        name: 'sangria',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'cochineal',
        name: 'cochineal',
        seasons: [WINTER],
    },
    {
        keyName: 'fuchsia',
        name: 'fuchsia',
        seasons: [WINTER],
    },
    {
        keyName: 'cerise-pink',
        name: 'cerise pink',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'hot-pink',
        name: 'hot pink',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'neon-pink',
        name: 'neon pink',
        seasons: [WINTER],
    },
    {
        keyName: 'crocus',
        name: 'crocus',
        seasons: [SUMMER],
    },
    {
        keyName: 'orchid',
        name: 'orchid',
        seasons: [SUMMER],
    },
    {
        keyName: 'shocking-pink',
        name: 'shocking pink',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'rose-madder',
        name: 'rose madder',
        seasons: [SUMMER],
    },
    {
        keyName: 'petal-pink',
        name: 'petal pink',
        seasons: [SUMMER],
    },
    {
        keyName: 'azalea-pink',
        name: 'azalea pink',
        seasons: [SUMMER],
    },
    {
        keyName: 'pink-fizz',
        name: 'pink fizz',
        seasons: [SPRING],
    },
    {
        keyName: 'bubblegum',
        name: 'bubblegum',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'lilac-pink',
        name: 'lilac pink',
        seasons: [SUMMER],
    },
    {
        keyName: 'blossom',
        name: 'blossom',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'cameo-rose',
        name: 'cameo rose',
        seasons: [SUMMER],
    },
    {
        keyName: 'heather-pink',
        name: 'heather pink',
        seasons: [SUMMER],
    },
    {
        keyName: 'soft-orchid',
        name: 'soft orchid',
        seasons: [SUMMER],
    },
    {
        keyName: 'tulipwood',
        name: 'tulipwood',
        seasons: [SUMMER],
    },
    {
        keyName: 'wine-blush',
        name: 'wine blush',
        seasons: [SUMMER],
    },
    {
        keyName: 'pink-raspberry',
        name: 'pink raspberry',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'festival-fuchsia',
        name: 'festival fuchsia',
        seasons: [WINTER],
    },
    {
        keyName: 'dark-amethyst',
        name: 'dark amethyst',
        seasons: [WINTER],
    },
    {
        keyName: 'beetroot',
        name: 'beetroot',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'mulberry',
        name: 'mulberry',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'soft-blackberry',
        name: 'soft blackberry',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'plum-purple',
        name: 'plum purple',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'cassis',
        name: 'cassis',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'deep-claret',
        name: 'deep claret',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'bordeaux',
        name: 'bordeaux',
        seasons: [SUMMER],
    },
    {
        keyName: 'burgundy',
        name: 'burgundy',
        seasons: [SUMMER],
    },
    {
        keyName: 'persian-red',
        name: 'persian red',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'aubergine',
        name: 'aubergine',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'smoked-grape',
        name: 'smoked grape',
        seasons: [SUMMER],
    },
    {
        keyName: 'fig',
        name: 'fig',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'pansy',
        name: 'pansy',
        seasons: [SPRING, SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'berry-cocktail',
        name: 'berry cocktail',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'bright-violet',
        name: 'bright violet',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'violet',
        name: 'violet',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'wisteria',
        name: 'wisteria',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'soft-violet',
        name: 'soft violet',
        seasons: [SUMMER],
    },
    {
        keyName: 'lavender',
        name: 'lavender',
        seasons: [SUMMER],
    },
    {
        keyName: 'vivid-violet',
        name: 'vivid violet',
        seasons: [SPRING, WINTER],
    },
    {
        keyName: 'jewel-lobelia',
        name: 'jewel lobelia',
        seasons: [WINTER],
    },
    {
        keyName: 'royal-purple',
        name: 'royal purple',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'ganzi-purple',
        name: 'ganzi purple',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'nightshade',
        name: 'nightshade',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'heliotrope',
        name: 'heliotrope',
        seasons: [SPRING, SUMMER, AUTUMN],
    },
    {
        keyName: 'lobelia',
        name: 'lobelia',
        seasons: [SPRING, WINTER],
    },
    {
        keyName: 'hyacinth',
        name: 'hyacinth',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'midnight',
        name: 'midnight',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'indigo',
        name: 'indigo',
        seasons: [WINTER],
    },
    {
        keyName: 'breton-blue',
        name: 'breton blue',
        seasons: [SPRING, WINTER],
    },
    {
        keyName: 'iris',
        name: 'iris',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'star-blue',
        name: 'star blue',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'bright-navy',
        name: 'bright navy',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'light-navy-silver',
        name: 'light navy & silver',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'navy',
        name: 'navy',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'sultry-navy',
        name: 'sultry navy',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'nautical-blue',
        name: 'nautical blue',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'sapphire',
        name: 'sapphire',
        seasons: [WINTER],
    },
    {
        keyName: 'delphinium',
        name: 'delphinium',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'cobalt-blue',
        name: 'cobalt blue',
        seasons: [SPRING, WINTER],
    },
    {
        keyName: 'periwinkle',
        name: 'periwinkle',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'provence',
        name: 'provence',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'cornflower',
        name: 'cornflower',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'sky-blue',
        name: 'sky blue',
        seasons: [SUMMER],
    },
    {
        keyName: 'dusty-blue',
        name: 'dusty blue',
        seasons: [SUMMER],
    },
    {
        keyName: 'oxford-blue',
        name: 'oxford blue',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'campanula',
        name: 'campanula',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'chinese-blue',
        name: 'chinese blue',
        seasons: [SPRING, WINTER],
    },
    {
        keyName: 'dutch-blue',
        name: 'dutch blue',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'blue-jewel',
        name: 'blue jewel',
        seasons: [SPRING, WINTER],
    },
    {
        keyName: 'light-aqua',
        name: 'light aqua',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'aqua',
        name: 'aqua',
        seasons: [SPRING],
    },
    {
        keyName: 'malibu',
        name: 'malibu',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'bluebird',
        name: 'bluebird',
        seasons: [SPRING, WINTER],
    },
    {
        keyName: 'bluebird-gold',
        name: 'bluebird & gold',
        seasons: [SPRING],
    },
    {
        keyName: 'bright-teal',
        name: 'bright teal',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'deep-aqua',
        name: 'deep aqua',
        seasons: [SPRING, SUMMER, AUTUMN],
    },
    {
        keyName: 'indian-teal',
        name: 'indian teal',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'seaspray',
        name: 'seaspray',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'slate-blue',
        name: 'slate blue',
        seasons: [SUMMER],
    },
    {
        keyName: 'slate',
        name: 'slate',
        seasons: [SUMMER],
    },
    {
        keyName: 'rich-navy',
        name: 'rich navy',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'marine-navy',
        name: 'marine navy',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'shaded-spruce',
        name: 'shaded spruce',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'petrol-blue',
        name: 'petrol blue',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'deep-teal',
        name: 'deep teal',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'laguna-gold',
        name: 'laguna & gold',
        seasons: [AUTUMN],
    },
    {
        keyName: 'laguna',
        name: 'laguna',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'nile-blue',
        name: 'nile blue',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'sea-green',
        name: 'sea green',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'peacock',
        name: 'peacock',
        seasons: [SPRING, SUMMER, AUTUMN],
    },
    {
        keyName: 'soft-spruce',
        name: 'soft spruce',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'true-teal',
        name: 'true teal',
        seasons: [SPRING, SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'mallard',
        name: 'mallard',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'deep-seagreen',
        name: 'deep seagreen',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'blue-spruce',
        name: 'blue spruce',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'deep-lagoon',
        name: 'deep lagoon',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'jade',
        name: 'jade',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'hawaii',
        name: 'hawaii',
        seasons: [SPRING, SUMMER],
    },
    {
        keyName: 'aquamarine',
        name: 'aquamarine',
        seasons: [SPRING],
    },
    {
        keyName: 'clear-sky',
        name: 'clear sky',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'powder-blue',
        name: 'powder blue',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'glacier',
        name: 'glacier',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'duck-egg',
        name: 'duck egg',
        seasons: [SUMMER],
    },
    {
        keyName: 'crystal-blue',
        name: 'crystal blue',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'lavender-blue',
        name: 'lavender blue',
        seasons: [SUMMER],
    },
    {
        keyName: 'ice-blue',
        name: 'ice blue',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'mint',
        name: 'mint',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'paradise-green',
        name: 'paradise green',
        seasons: [SPRING, WINTER],
    },
    {
        keyName: 'pastel-apple',
        name: 'pastel apple',
        seasons: [SPRING],
    },
    {
        keyName: 'apple',
        name: 'apple',
        seasons: [SPRING],
    },
    {
        keyName: 'fresh-green',
        name: 'fresh green',
        seasons: [SPRING],
    },
    {
        keyName: 'leaf',
        name: 'leaf',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'pea-green',
        name: 'pea green',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'jelly-bean',
        name: 'jelly bean',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'island-green',
        name: 'island green',
        seasons: [SPRING],
    },
    {
        keyName: 'emerald',
        name: 'emerald',
        seasons: [WINTER],
    },
    {
        keyName: 'light-emerald',
        name: 'light emerald',
        seasons: [SPRING, WINTER],
    },
    {
        keyName: 'emerald-turquoise',
        name: 'emerald turquoise',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'dark-emerald',
        name: 'dark emerald',
        seasons: [AUTUMN, WINTER],
    },
    {
        keyName: 'tree-top',
        name: 'tree top',
        seasons: [AUTUMN],
    },
    {
        keyName: 'moss',
        name: 'moss',
        seasons: [AUTUMN],
    },
    {
        keyName: 'green-garden',
        name: 'green garden',
        seasons: [AUTUMN],
    },
    {
        keyName: 'forest-green',
        name: 'forest green',
        seasons: [AUTUMN, WINTER],
    },
    {
        keyName: 'pine',
        name: 'pine',
        seasons: [AUTUMN, WINTER],
    },
    {
        keyName: 'conifer',
        name: 'conifer',
        seasons: [AUTUMN, WINTER],
    },
    {
        keyName: 'alpine-green',
        name: 'alpine green',
        seasons: [AUTUMN, WINTER],
    },
    {
        keyName: 'dark-olive',
        name: 'dark olive',
        seasons: [AUTUMN],
    },
    {
        keyName: 'thyme',
        name: 'thyme',
        seasons: [AUTUMN],
    },
    {
        keyName: 'eucalyptus',
        name: 'eucalyptus',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'light-sage',
        name: 'light sage',
        seasons: [AUTUMN],
    },
    {
        keyName: 'apple-mint',
        name: 'apple mint',
        seasons: [SPRING],
    },
    {
        keyName: 'lime',
        name: 'lime',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'avocado',
        name: 'avocado',
        seasons: [AUTUMN],
    },
    {
        keyName: 'zesty-lime',
        name: 'zesty lime',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'lemongrass',
        name: 'lemongrass',
        seasons: [AUTUMN],
    },
    {
        keyName: 'antique-moss',
        name: 'antique moss',
        seasons: [AUTUMN],
    },
    {
        keyName: 'citronella',
        name: 'citronella',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'bronze',
        name: 'bronze',
        seasons: [AUTUMN],
    },
    {
        keyName: 'hazelnut',
        name: 'hazelnut',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'camel',
        name: 'camel',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'caramel',
        name: 'caramel',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'tan',
        name: 'tan',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'gingernut',
        name: 'gingernut',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'pecan',
        name: 'pecan',
        seasons: [AUTUMN],
    },
    {
        keyName: 'copper-rose',
        name: 'copper rose',
        seasons: [AUTUMN],
    },
    {
        keyName: 'coffee',
        name: 'coffee',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'rich-chocolate',
        name: 'rich chocolate',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'ganache',
        name: 'ganache',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'light-chocolate',
        name: 'light chocolate',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'chocolate',
        name: 'chocolate',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'black',
        name: 'black',
        seasons: [WINTER],
    },
    {
        keyName: 'midnight-grey',
        name: 'midnight grey',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'charcoal',
        name: 'charcoal',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'mole',
        name: 'mole',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'french-grey',
        name: 'french grey',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'dark-pebble-grey',
        name: 'dark pebble grey',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'dolphin-grey',
        name: 'dolphin grey',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'pewter',
        name: 'pewter',
        seasons: [SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'stone-grey',
        name: 'stone grey',
        seasons: [AUTUMN],
    },
    {
        keyName: 'soft-camel',
        name: 'soft camel',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'honey',
        name: 'honey',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'light-sand',
        name: 'light sand',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'macadamia',
        name: 'macadamia',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'buttermilk',
        name: 'buttermilk',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'pebble-grey',
        name: 'pebble grey',
        seasons: [SPRING, SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'greystone',
        name: 'greystone',
        seasons: [SPRING, SUMMER, AUTUMN, WINTER],
    },
    {
        keyName: 'mushroom',
        name: 'mushroom',
        seasons: [SUMMER, AUTUMN],
    },
    {
        keyName: 'dove-grey',
        name: 'dove grey',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'silver',
        name: 'silver',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'silver-marl',
        name: 'silver marl',
        seasons: [SPRING, SUMMER, WINTER],
    },
    {
        keyName: 'white',
        name: 'white',
        seasons: [WINTER],
    },
    {
        keyName: 'silver-moon',
        name: 'silver moon',
        seasons: [SUMMER, WINTER],
    },
    {
        keyName: 'soft-white',
        name: 'soft white',
        seasons: [SPRING, SUMMER, AUTUMN],
    },
    {
        keyName: 'ivory',
        name: 'ivory',
        seasons: [SPRING, SUMMER, AUTUMN],
    },
    {
        keyName: 'antique-white',
        name: 'antique white',
        seasons: [SPRING, AUTUMN],
    },
    {
        keyName: 'light-birch-gold',
        name: 'light birch & gold',
        seasons: [SPRING, AUTUMN],
    },
];
