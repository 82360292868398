import { AUTUMN, SPRING, SUMMER, WINTER } from './seasons';
import {
    SOURCE_COLOR_MUSE_CARD,
    SOURCE_COLOR_MUSE_FABRIC,
    SOURCE_PICO_ADOBE_RGB,
    SOURCE_PICO_SRGB,
} from './color-sources';

export const acidYellow = {
    id: 'acid-yellow',
    name: 'acid yellow',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#ECE7A1',
        '#ECE7A1',
        '#ECE7A1',
        '#EDE7A2',
        '#EDE8A2',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#EEEEA5',
        '#EEEEA5',
        '#EEEEA5',
        '#EEEEA4',
        '#EEEEA5',
    ],
    [SOURCE_PICO_SRGB]: ['#F2EB9A', '#F2EB99', '#F2EB9A', '#F3EC9A', '#F3EB9A'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F0EB9E',
        '#F0EB9E',
        '#F0EB9E',
        '#F1EC9F',
        '#F1EB9E',
    ],
};

export const airforceBlue = {
    id: 'airforce-blue',
    name: 'airforce blue',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#5A7096',
        '#5A7096',
        '#5A7096',
        '#5A7097',
        '#5A7097',
    ],
    [SOURCE_PICO_SRGB]: ['#6A7895', '#6A7996', '#6B7A97', '#6B7A97', '#6A7997'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#6E7894',
        '#6F7995',
        '#707A96',
        '#707A96',
        '#6F7996',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#3D5876', '#3D5876', '#3E5877'],
};

export const amber = {
    id: 'amber',
    name: 'amber',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D07C3C',
        '#D07C3D',
        '#D17B3C',
        '#D17C3D',
        '#D17D3C',
    ],
    [SOURCE_PICO_SRGB]: ['#EB923E', '#EA9140', '#EB9141', '#EB9240', '#EB913F'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D69244',
        '#D69145',
        '#D69146',
        '#D69245',
        '#D69145',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#C5713C', '#C5713C', '#C4713C'],
};

export const amethyst = {
    id: 'amethyst',
    name: 'amethyst',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#A53D85',
        '#A53D85',
        '#A53E85',
        '#A53D85',
        '#A53D85',
    ],
    [SOURCE_PICO_SRGB]: ['#BD5D83', '#BC5C85', '#BE5E84', '#BD5D83', '#BC5B83'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#A95D82',
        '#A85C83',
        '#A95E83',
        '#A95D82',
        '#A75B82',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#A55181', '#A55181', '#A45080'],
};

export const appleGreen = {
    id: 'apple-green',
    name: 'apple green',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#98DA90',
        '#99DB91',
        '#99DB91',
        '#99DB91',
        '#99DB92',
    ],
    [SOURCE_PICO_SRGB]: ['#97DF8C', '#98DF8C', '#98DE8D', '#98DF8D', '#98DF8D'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#B0DF91',
        '#B0DF91',
        '#B0DE91',
        '#B0DF91',
        '#B0DF92',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#95E69C', '#96E79D', '#97E79E'],
};

export const appleJade = {
    id: 'apple-jade',
    name: 'apple jade',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#A6C18E',
        '#A6C18E',
        '#A6C18D',
        '#A6C18E',
        '#A6C18D',
    ],
    [SOURCE_PICO_SRGB]: ['#B2CA8A', '#B1CA89', '#B2CB8A', '#B2CB8A', '#B2CA88'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#BACA8D',
        '#B9CA8D',
        '#BACB8D',
        '#B9CB8D',
        '#BACA8C',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#9DB997', '#9DB897', '#9DB997'],
};

export const apricot = {
    id: 'apricot',
    name: 'apricot',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#EABD90',
        '#E9BD90',
        '#E9BC8F',
        '#E9BC90',
        '#E9BB8F',
    ],
    [SOURCE_PICO_SRGB]: ['#FDC98B', '#FDC88A', '#FCC88C', '#FDC88B', '#FDC88B'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F0C98F',
        '#EFC88E',
        '#EFC88F',
        '#F0C88E',
        '#F0C88F',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FFBA96', '#FFBA96', '#FFBB98'],
};

export const aqua = {
    id: 'aqua',
    name: 'aqua',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#37C1CF',
        '#35C1CF',
        '#37C1CF',
        '#37C1CF',
        '#36C1CF',
    ],
    [SOURCE_PICO_SRGB]: ['#3EC4D1', '#3DC5D1', '#3BC4D1', '#3EC4D2', '#3FC4D1'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#79C4D1',
        '#79C5D1',
        '#78C4D1',
        '#79C4D1',
        '#7AC4D0',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#00BBC9', '#00BBC9', '#00BAC9'],
};

export const aquamarine = {
    id: 'aquamarine',
    name: 'aquamarine',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#00A3A1',
        '#00A3A1',
        '#00A3A1',
        '#00A4A1',
        '#00A3A1',
    ],
    [SOURCE_PICO_SRGB]: ['#15AFA0', '#14AEA1', '#18AEA0', '#1AAEA1', '#1BAEA0'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#64AFA0',
        '#64AEA1',
        '#64AEA1',
        '#64AEA2',
        '#64AEA1',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#009FA4', '#00A0A5', '#00A0A5'],
};

export const banana = {
    id: 'banana',
    name: 'banana',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#F0E7B2',
        '#F0E7B2',
        '#F0E8B2',
        '#F0E8B3',
        '#F0E7B3',
    ],
    [SOURCE_PICO_SRGB]: ['#F9ECAB', '#F9ECAC', '#F9ECAC', '#F9ECAC', '#F9ECAC'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F5ECAF',
        '#F5ECAF',
        '#F6ECAF',
        '#F5ECAF',
        '#F5ECAF',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F3E3BC', '#F3E2BB', '#F3E3BB'],
};

export const beige = {
    id: 'beige',
    name: 'beige',
    seasons: [AUTUMN, SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#DCB696',
        '#DCB696',
        '#DCB696',
        '#DCB696',
        '#DCB696',
    ],
    [SOURCE_PICO_SRGB]: ['#EDC192', '#EEC192', '#EEC192', '#EDC092', '#EDC192'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E2C194',
        '#E2C194',
        '#E2C195',
        '#E2C095',
        '#E2C194',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#C8AE95', '#C8AE95', '#C8AE95'],
};

export const black = {
    id: 'black',
    name: 'black',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#383E3E',
        '#383D3E',
        '#393E3F',
        '#393E3F',
        '#3A3F40',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#353B3C',
        '#363B3C',
        '#353B3C',
        '#363B3C',
        '#373B3C',
    ],
    [SOURCE_PICO_SRGB]: ['#4B4647', '#4D4846', '#4A4746', '#4C4647', '#4B4845'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#4A4647',
        '#4C4846',
        '#494746',
        '#4A4647',
        '#4A4845',
    ],
};

export const brick = {
    id: 'brick',
    name: 'brick',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#BF2E38',
        '#C02E38',
        '#C02E38',
        '#C12F39',
        '#C12F39',
    ],
    [SOURCE_PICO_SRGB]: ['#E0593D', '#E15A3D', '#E15B3C', '#E1593B', '#E1593B'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#C6593E',
        '#C65A3E',
        '#C65B3D',
        '#C6593D',
        '#C6593C',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#C23838', '#C23838', '#C23939'],
};

export const brightBlue = {
    id: 'bright-blue',
    name: 'bright blue',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#5790BD',
        '#5690BD',
        '#5790BD',
        '#5690BD',
        '#5690BD',
    ],
    [SOURCE_PICO_SRGB]: ['#5991B8', '#5A92B9', '#5B92B9', '#5A92B9', '#5991B9'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#6D91B7',
        '#6E92B8',
        '#6E92B8',
        '#6D92B8',
        '#6D91B7',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#3D75C6', '#3E75C7', '#3E75C7'],
};

export const brightNavy = {
    id: 'bright-navy',
    name: 'bright navy',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#1C3966',
        '#1E3965',
        '#1E3965',
        '#1E3965',
        '#1E3864',
    ],
    [SOURCE_PICO_SRGB]: ['#3B4167', '#3C4067', '#3A4067', '#3B4066', '#3A3F66'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#3D4166',
        '#3D4066',
        '#3C4066',
        '#3D4065',
        '#3C3F65',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#2C4267', '#2C4167', '#2B4267'],
};

export const bronze = {
    id: 'bronze',
    name: 'bronze',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#554732',
        '#544832',
        '#544832',
        '#554832',
        '#554831',
    ],
    [SOURCE_PICO_SRGB]: ['#615239', '#615133', '#5F502F', '#615130', '#615135'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#5D523A',
        '#5D5134',
        '#5B5031',
        '#5C5132',
        '#5D5136',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#665443', '#665542', '#665543'],
};

export const burgundy = {
    id: 'burgundy',
    name: 'burgundy',
    seasons: [WINTER, SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#863D56',
        '#873D56',
        '#883C56',
        '#873D57',
        '#883D57',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#863443',
        '#863443',
        '#863443',
        '#863443',
        '#853343',
    ],
    [SOURCE_PICO_SRGB]: ['#9E575A', '#A0595B', '#9E575B', '#9E565C', '#9F585C'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#8E575A',
        '#90595B',
        '#8E575B',
        '#8E565B',
        '#8F585C',
    ],
};

export const camel = {
    id: 'camel',
    name: 'camel',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D5A37B',
        '#D5A37A',
        '#D5A47B',
        '#D5A47C',
        '#D5A37C',
    ],
    [SOURCE_PICO_SRGB]: ['#E8B078', '#E8B077', '#E8B079', '#E9B079', '#E8B078'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DAB07B',
        '#DAB07A',
        '#DAB07C',
        '#DAB07C',
        '#DAB07B',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#BB9377', '#BA9176', '#BB9377'],
};

export const canaryYellow = {
    id: 'canary-yellow',
    name: 'canary yellow',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#F5E067',
        '#F5E066',
        '#F5E069',
        '#F6E16A',
        '#F5E16A',
    ],
    [SOURCE_PICO_SRGB]: ['#FFE962', '#FFE961', '#FFE864', '#FFE963', '#FFE863'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#FBE96D',
        '#FBE96C',
        '#FAE86E',
        '#FAE96D',
        '#FAE86D',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FAE988', '#FBE989', '#FBE989'],
};

export const carmine = {
    id: 'carmine',
    name: 'carmine',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#C3283C',
        '#C2293D',
        '#C2293C',
        '#C2293C',
        '#C3293D',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#A43243',
        '#A43444',
        '#A43344',
        '#A43343',
        '#A43344',
    ],
    [SOURCE_PICO_SRGB]: ['#DF593F', '#DF593F', '#DE5A3C', '#DF5B40', '#E05A40'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#C55940',
        '#C45940',
        '#C45A3E',
        '#C55B41',
        '#C65A41',
    ],
};

export const cerise = {
    id: 'cerise',
    name: 'cerise',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#E9729D',
        '#E9729E',
        '#E9719D',
        '#E9729D',
        '#E9729E',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#E766A3',
        '#E767A4',
        '#E666A3',
        '#E667A3',
        '#E767A3',
    ],
    [SOURCE_PICO_SRGB]: ['#F47E99', '#F57E9A', '#F47D9B', '#F47E99', '#F57F9A'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DB7E98',
        '#DB7E99',
        '#DB7D9A',
        '#DB7E98',
        '#DC7F99',
    ],
};

export const charcoal = {
    id: 'charcoal',
    name: 'charcoal',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#505A5E',
        '#515B5F',
        '#505B5F',
        '#515C60',
        '#515C60',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#4C5258',
        '#4C5258',
        '#4D5259',
        '#4C5258',
        '#4C5258',
    ],
    [SOURCE_PICO_SRGB]: ['#5B605F', '#5C5E61', '#5C6061', '#5C6061', '#5B6061'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#5C605F',
        '#5D5E61',
        '#5D6061',
        '#5D6061',
        '#5D6061',
    ],
};

export const cherry = {
    id: 'cherry',
    name: 'cherry',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#C61A4B',
        '#C61A4B',
        '#C61B4C',
        '#C61B4C',
        '#C61B4C',
    ],
    [SOURCE_PICO_SRGB]: ['#F25D4C', '#F25B4B', '#F25E4D', '#F25D4E', '#F35E4E'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D55D4D',
        '#D55B4C',
        '#D55E4D',
        '#D55D4E',
        '#D65E4F',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#9C2E3D', '#9C2F3D', '#9C2E3D'],
};

export const chesnut = {
    id: 'chesnut',
    name: 'chesnut',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#A53E43',
        '#A53F43',
        '#A53E42',
        '#A53D42',
        '#A53F43',
    ],
    [SOURCE_PICO_SRGB]: ['#BD5A41', '#BE5843', '#BF5B43', '#BE5A45', '#BF5B48'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#A85A42',
        '#A95844',
        '#AA5B44',
        '#A95A46',
        '#AA5B49',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#803F37', '#7F3E36', '#7F3F37'],
};

export const chocolate = {
    id: 'chocolate',
    name: 'chocolate',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#775249',
        '#77524A',
        '#775249',
        '#775149',
        '#775149',
    ],
    [SOURCE_PICO_SRGB]: ['#8B624E', '#8B634D', '#8B624C', '#8B624E', '#8A634E'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#81624F',
        '#81634E',
        '#81624D',
        '#81624F',
        '#81634F',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#6C4C40', '#6B4B3F', '#6C4C40'],
};

export const cinnamon = {
    id: 'cinnamon',
    name: 'cinnamon',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D99D52',
        '#DA9D52',
        '#DA9D51',
        '#D99D51',
        '#DA9D51',
    ],
    [SOURCE_PICO_SRGB]: ['#F0AD52', '#F0AD51', '#F0AD51', '#F0AD4F', '#F0AC51'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DFAD58',
        '#E0AD57',
        '#E0AD58',
        '#DFAD55',
        '#DFAC57',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#B07A43', '#B07842', '#B07942'],
};

export const clover = {
    id: 'clover',
    name: 'clover',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#E0B4C2',
        '#E0B3C2',
        '#E0B3C1',
        '#E0B3C1',
        '#E0B3C2',
    ],
    [SOURCE_PICO_SRGB]: ['#F0BABB', '#F0BABA', '#F0BABA', '#F0BABB', '#F0BABB'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E3BABB',
        '#E2BABA',
        '#E2BABA',
        '#E3BABB',
        '#E3BABB',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#DE96AA', '#DE98AA', '#DE96AA'],
};

export const coffee = {
    id: 'coffee',
    name: 'coffee',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#966E54',
        '#966E54',
        '#966E54',
        '#956E54',
        '#966E54',
    ],
    [SOURCE_PICO_SRGB]: ['#A97D54', '#A97D56', '#A97D56', '#A97D55', '#A97E56'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#9E7D56',
        '#9E7D58',
        '#9E7D58',
        '#9E7D57',
        '#9E7E58',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#755A4A', '#755A4A', '#755B4A'],
};

export const coral = {
    id: 'coral',
    name: 'coral',
    seasons: [SPRING, AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#E07270',
        '#E07270',
        '#E0726F',
        '#E0716E',
        '#E0716E',
    ],
    [SOURCE_PICO_SRGB]: ['#FE8D6D', '#FF8C6D', '#FF8C6D', '#FF8C6D', '#FF8C6C'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E58D6E',
        '#E68C6F',
        '#E68C6E',
        '#E68C6E',
        '#E68C6D',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F0747B', '#F0747C', '#F0757C'],
};

export const coralRed = {
    id: 'coral-red',
    name: 'coral red',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D6395D',
        '#D63A5E',
        '#D6395E',
        '#D63A5E',
        '#D63A5E',
    ],
    [SOURCE_PICO_SRGB]: ['#FE6B61', '#FF6C61', '#FF6D63', '#FF6C63', '#FF6B61'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E16B62',
        '#E26C62',
        '#E26D63',
        '#E26C63',
        '#E16B62',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#DD3E50', '#DE4052', '#DD3F51'],
};

export const cornflower = {
    id: 'cornflower',
    name: 'cornflower',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#5687C2',
        '#5687C2',
        '#5687C2',
        '#5788C2',
        '#5687C2',
    ],
    [SOURCE_PICO_SRGB]: ['#5B8BC1', '#5B8BC1', '#5E8BC1', '#5C8BC1', '#5C8AC1'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#6C8BBF',
        '#6C8BBF',
        '#6D8BBF',
        '#6C8BBF',
        '#6C8ABF',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#3D78BC', '#3D79BC', '#3C78BC'],
};

export const cornYellow = {
    id: 'corn-yellow',
    name: 'corn yellow',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#F3DB88',
        '#F3DB88',
        '#F3DB88',
        '#F3DB88',
        '#F3DB87',
    ],
    [SOURCE_PICO_SRGB]: ['#FFE381', '#FFE381', '#FFE380', '#FFE380', '#FFE380'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F7E387',
        '#F7E387',
        '#F8E386',
        '#F8E386',
        '#F7E386',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FEE18E', '#FEE08E', '#FEE08D'],
};

export const cream = {
    id: 'cream',
    name: 'cream',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#EBE9DE',
        '#EBE9DE',
        '#EBE9DD',
        '#EBE9DE',
        '#EBE9DE',
    ],
    [SOURCE_PICO_SRGB]: ['#F3ECDA', '#F2ECDA', '#F2ECDA', '#F3ECDA', '#F2EBDA'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F1ECDB',
        '#F0ECDB',
        '#F0ECDB',
        '#F1ECDB',
        '#F0EBDB',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F1EDE4', '#F1EDE4', '#F0ECE4'],
};

export const cyclamen = {
    id: 'cyclamen',
    name: 'cyclamen',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#DB9AB1',
        '#DB9AB1',
        '#DB99B0',
        '#DB9BB1',
        '#DC9AB1',
    ],
    [SOURCE_PICO_SRGB]: ['#F0A6AB', '#EFA5AA', '#F0A6AA', '#EFA6AB', '#F0A6AA'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DEA6AA',
        '#DEA5AA',
        '#DEA6AA',
        '#DEA6AB',
        '#DEA6AA',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#D07F9E', '#CF809E', '#D07F9D'],
};

export const damson = {
    id: 'damson',
    name: 'damson',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#6C3A70',
        '#6D3B70',
        '#6C3B70',
        '#6B3B70',
        '#6C3B71',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#5F3650',
        '#5F364F',
        '#603650',
        '#5F354F',
        '#5F364F',
    ],
    [SOURCE_PICO_SRGB]: ['#7A4B6E', '#7B4B70', '#7B4B6E', '#794B6D', '#7B4B6F'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#6F4B6C',
        '#704B6F',
        '#704B6D',
        '#6E4B6B',
        '#704B6E',
    ],
};

export const darkBlueGrey = {
    id: 'dark-blue-grey',
    name: 'dark blue grey',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#7888A0',
        '#79889F',
        '#7988A0',
        '#7989A1',
        '#7889A1',
    ],
    [SOURCE_PICO_SRGB]: ['#858F9E', '#848F9F', '#86909F', '#848F9E', '#858F9D'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#888F9D',
        '#878F9E',
        '#88909E',
        '#878F9E',
        '#888F9D',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#6B7A89', '#6B7B89', '#6B7B89'],
};

export const darkBrown = {
    id: 'dark-brown',
    name: 'dark brown',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#664B45',
        '#674B45',
        '#664B45',
        '#674B45',
        '#664B45',
    ],
    [SOURCE_PICO_SRGB]: ['#795A49', '#785748', '#78584A', '#78594A', '#78584A'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#715A4A',
        '#705749',
        '#70584A',
        '#70594A',
        '#70584B',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#4F3F3A', '#4E3E3A', '#4E3F3A'],
};

export const darkEmerald = {
    id: 'dark-emerald',
    name: 'dark emerald',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#0B7B59',
        '#097A58',
        '#097B58',
        '#0A7B58',
        '#067B59',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#007250',
        '#007250',
        '#007250',
        '#007350',
        '#007350',
    ],
    [SOURCE_PICO_SRGB]: ['#368658', '#368659', '#398659', '#39875A', '#3A865A'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#57865B',
        '#56865B',
        '#58865C',
        '#58875C',
        '#58865C',
    ],
};

export const darkOlive = {
    id: 'dark-olive',
    name: 'dark olive',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#405042',
        '#405042',
        '#405043',
        '#405143',
        '#415143',
    ],
    [SOURCE_PICO_SRGB]: ['#4E5845', '#4F5746', '#4F5847', '#4D5748', '#505948'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#515846',
        '#515747',
        '#525848',
        '#505748',
        '#525949',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#434D3E', '#434C3E', '#444D3E'],
};

export const delphinium = {
    id: 'delphinium',
    name: 'delphinium',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#5C67A1',
        '#5D67A1',
        '#5C68A1',
        '#5D68A1',
        '#5C68A1',
    ],
    [SOURCE_PICO_SRGB]: ['#6E72A1', '#6C70A0', '#6D709F', '#6E71A0', '#6E70A1'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#6F729F',
        '#6D709E',
        '#6E709E',
        '#6F719F',
        '#6E709F',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#4E6797', '#4E6797', '#4D6796'],
};

export const doveGrey = {
    id: 'dove-grey',
    name: 'dove grey',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#B8B0B3',
        '#B8B0B3',
        '#B8AFB4',
        '#B8B0B4',
        '#B8B0B4',
    ],
    [SOURCE_PICO_SRGB]: ['#BFB2AE', '#C0B2AE', '#BFB1AE', '#C0B2AE', '#C0B1AE'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#BCB2AE',
        '#BCB2AE',
        '#BBB1AE',
        '#BCB2AE',
        '#BCB1AE',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#98989F', '#98979E', '#98979E'],
};

export const duckEgg = {
    id: 'duck-egg',
    name: 'duck egg',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#99CBDB',
        '#98CADB',
        '#98CADB',
        '#97CADB',
        '#98CADB',
    ],
    [SOURCE_PICO_SRGB]: ['#9BCDD7', '#9CCDD7', '#9BCED7', '#9CCDD7', '#9DCED7'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#ABCDD7',
        '#ACCDD7',
        '#ACCED7',
        '#ACCDD7',
        '#ADCED7',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#8BC4D1', '#8CC4D2', '#8CC4D2'],
};

export const dustyPink = {
    id: 'dusty-pink',
    name: 'dusty pink',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#EACEC9',
        '#EBCEC9',
        '#EBCEC9',
        '#EACEC9',
        '#EBCEC9',
    ],
    [SOURCE_PICO_SRGB]: ['#F7D4C3', '#F7D4C4', '#F7D4C4', '#F7D4C3', '#F7D3C3'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EED4C4',
        '#EED4C4',
        '#EED4C4',
        '#EED4C4',
        '#EED3C4',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#DFBEB4', '#DFBEB4', '#DFBEB4'],
};

export const electricBlue = {
    id: 'electric-blue',
    name: 'electric blue',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#004F9E',
        '#004F9E',
        '#00509E',
        '#00509F',
        '#00519F',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#0A50A2',
        '#0651A2',
        '#0750A2',
        '#0650A2',
        '#0650A2',
    ],
    [SOURCE_PICO_SRGB]: ['#004DA2', '#004DA2', '#004DA1', '#004BA1', '#004CA1'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#144D9F',
        '#174D9F',
        '#144D9F',
        '#0F4B9F',
        '#114C9F',
    ],
};

export const flamingoPink = {
    id: 'flamingo-pink',
    name: 'flamingo pink',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#FC838A',
        '#FC848A',
        '#FC838A',
        '#FC838A',
        '#FC838A',
    ],
    [SOURCE_PICO_SRGB]: ['#FF8B85', '#FF8A85', '#FF8A85', '#FF8A84', '#FF8A84'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EB8B85',
        '#EB8A85',
        '#EB8A85',
        '#EB8A85',
        '#EB8A84',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#E96680', '#E9677F', '#E9677F'],
};

export const forestGreen = {
    id: 'forest-green',
    name: 'forest green',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#097A67',
        '#0A7A67',
        '#0D7A67',
        '#0D7B67',
        '#0A7A66',
    ],
    [SOURCE_PICO_SRGB]: ['#378468', '#378569', '#348566', '#328367', '#348465'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#568469',
        '#56856A',
        '#558568',
        '#548368',
        '#558467',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#246A57', '#246A58', '#246A57'],
};

export const frenchNavy = {
    id: 'french-navy',
    name: 'french navy',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#49577D',
        '#49587D',
        '#49597D',
        '#4B597E',
        '#4A597E',
    ],
    [SOURCE_PICO_SRGB]: ['#58607C', '#58607C', '#5A627D', '#58607D', '#5D627E'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#5A607B',
        '#5B607B',
        '#5C627C',
        '#5B607C',
        '#5E627D',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#33424F', '#344351', '#354250'],
};

export const fuchsia = {
    id: 'fuchsia',
    name: 'fuchsia',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#BD327B',
        '#BD327B',
        '#BD327C',
        '#BD337C',
        '#BD357C',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#B23268',
        '#B23267',
        '#B13167',
        '#B13167',
        '#B23167',
    ],
    [SOURCE_PICO_SRGB]: ['#DD5B7A', '#DC5B79', '#DC5978', '#DC5A78', '#DD5B7A'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#C35B79',
        '#C35B78',
        '#C25977',
        '#C25A77',
        '#C45B79',
    ],
};

export const geranium = {
    id: 'geranium',
    name: 'geranium',
    seasons: [SPRING, AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#DF5242',
        '#DF5041',
        '#DF5142',
        '#DF5343',
        '#DF5241',
    ],
    [SOURCE_PICO_SRGB]: ['#FF7445', '#FF7544', '#FF7545', '#FF7541', '#FF7642'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E37447',
        '#E47546',
        '#E47548',
        '#E47544',
        '#E47645',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#D93233', '#D93033', '#D93132'],
};

export const geraniumPink = {
    id: 'geranium-pink',
    name: 'geranium pink',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#F797AA',
        '#F697AA',
        '#F698AA',
        '#F798AB',
        '#F798AB',
    ],
    [SOURCE_PICO_SRGB]: ['#FF9EA5', '#FF9EA5', '#FF9EA5', '#FF9EA5', '#FF9EA5'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E99EA4',
        '#EA9EA5',
        '#EA9EA5',
        '#EA9EA5',
        '#EA9EA4',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F58F9F', '#F58E9E', '#F58E9E'],
};

export const grassGreen = {
    id: 'grass-green',
    name: 'grass green',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#78B157',
        '#78B157',
        '#78B157',
        '#78B157',
        '#78B157',
    ],
    [SOURCE_PICO_SRGB]: ['#89BE52', '#89BE53', '#89BE53', '#88BE53', '#88BE51'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#9ABE5A',
        '#9ABE5B',
        '#9ABE5A',
        '#9ABE5A',
        '#9ABE5A',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#5FA259', '#5FA159', '#61A25A'],
};

export const grey = {
    id: 'grey',
    name: 'grey',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#9E9D9D',
        '#9E9E9D',
        '#9E9E9E',
        '#9E9F9E',
        '#9E9F9E',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#7B818A',
        '#7C828B',
        '#7B818A',
        '#7C828B',
        '#7C828B',
    ],
    [SOURCE_PICO_SRGB]: ['#A19F98', '#A19F98', '#A3A099', '#A29F99', '#A29F99'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#A09F98',
        '#A09F98',
        '#A2A09A',
        '#A19F99',
        '#A19F99',
    ],
};

export const heliotrope = {
    id: 'heliotrope',
    name: 'heliotrope',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#74649E',
        '#74649E',
        '#74649E',
        '#74649E',
        '#75659F',
    ],
    [SOURCE_PICO_SRGB]: ['#836F9B', '#84709C', '#846F9C', '#856F9C', '#846E9B'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#7E6F99',
        '#7F709A',
        '#7F6F9A',
        '#7F6F9B',
        '#7E6E9A',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#5366A1', '#5267A1', '#5166A1'],
};

export const honey = {
    id: 'honey',
    name: 'honey',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D4A878',
        '#D4A878',
        '#D5A878',
        '#D5A979',
        '#D5A878',
    ],
    [SOURCE_PICO_SRGB]: ['#E6B376', '#E7B474', '#E6B375', '#E7B374', '#E7B375'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D9B379',
        '#DAB478',
        '#D9B379',
        '#DAB378',
        '#DAB378',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#D3A275', '#D3A075', '#D29F74'],
};

export const hyacinth = {
    id: 'hyacinth',
    name: 'hyacinth',
    seasons: [SUMMER, SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#86A1D5',
        '#86A0D4',
        '#86A1D4',
        '#87A1D4',
        '#87A1D4',
    ],
    [SOURCE_PICO_SRGB]: ['#85A1CF', '#85A2CF', '#87A3D0', '#87A3D1', '#87A3D0'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#8EA1CE',
        '#8EA2CE',
        '#8FA3CE',
        '#90A3CF',
        '#90A3CF',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#8697D6', '#8697D6', '#8596D6'],
};

export const iceAqua = {
    id: 'ice-aqua',
    name: 'ice aqua',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D3E5E2',
        '#D2E5E2',
        '#D3E4E2',
        '#D2E5E2',
        '#D3E5E2',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#CAE4E0',
        '#CAE3DF',
        '#CAE4DF',
        '#CAE4E0',
        '#CAE3DF',
    ],
    [SOURCE_PICO_SRGB]: ['#D5E6DE', '#D4E6DE', '#D5E6DE', '#D5E6DF', '#D6E6E0'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DAE6DF',
        '#DAE6DF',
        '#DAE6DE',
        '#DAE6DF',
        '#DBE6E0',
    ],
};

export const iceBlue = {
    id: 'ice-blue',
    name: 'ice blue',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D9E2E3',
        '#D8E3E4',
        '#D9E3E4',
        '#D9E3E4',
        '#D9E3E4',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#D9E2EF',
        '#D6DFED',
        '#D6E0ED',
        '#D6E0ED',
        '#D7E0EE',
    ],
    [SOURCE_PICO_SRGB]: ['#DCE4DF', '#DCE4DF', '#DCE4DF', '#DBE4DF', '#DBE4DE'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DEE4DF',
        '#DEE4DF',
        '#DEE4DF',
        '#DEE4DF',
        '#DEE4DE',
    ],
};

export const iceGreen = {
    id: 'ice-green',
    name: 'ice green',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#E6E7D7',
        '#E5E7D7',
        '#E5E7D7',
        '#E5E6D6',
        '#E5E6D6',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#E2EDE1',
        '#E4EDE2',
        '#E3EDE2',
        '#E3EDE2',
        '#E3EDE2',
    ],
    [SOURCE_PICO_SRGB]: ['#E9E9D3', '#E9E9D3', '#EAE9D3', '#EAE9D3', '#EAE9D3'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E9E9D4',
        '#E9E9D4',
        '#E9E9D4',
        '#EAE9D4',
        '#EAE9D4',
    ],
};

export const iceHyacinth = {
    id: 'ice-hyacinth',
    name: 'ice hyacinth',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D5DBE4',
        '#D4DAE3',
        '#D4DAE1',
        '#D4DBE3',
        '#D5DBE4',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#C4D4E9',
        '#C4D4E9',
        '#C4D4E9',
        '#C3D3E9',
        '#C3D4E9',
    ],
    [SOURCE_PICO_SRGB]: ['#D9DCDE', '#DBD6DE', '#D9DCDE', '#D8DBDD', '#DADCDE'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DADCDE',
        '#DAD6DD',
        '#DADCDE',
        '#D9DBDD',
        '#DADCDE',
    ],
};

export const iceLavender = {
    id: 'ice-lavender',
    name: 'ice lavender',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#DEDBDF',
        '#DDDADF',
        '#DDDADF',
        '#DEDBDF',
        '#DEDADF',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#CBC7EA',
        '#CBC7E9',
        '#CBC7EA',
        '#CBC6EA',
        '#CAC6E9',
    ],
    [SOURCE_PICO_SRGB]: ['#E1DBD8', '#E1DBD8', '#E2DBD8', '#E1DBD8', '#E2DBD8'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DFDBD8',
        '#E0DBD8',
        '#E0DBD9',
        '#E0DBD8',
        '#E0DBD8',
    ],
};

export const iceLemon = {
    id: 'ice-lemon',
    name: 'ice lemon',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#ECE9D8',
        '#ECE9D7',
        '#ECE9D7',
        '#EBE9D7',
        '#ECEAD8',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#F3F3E2',
        '#F3F3E2',
        '#F3F3E2',
        '#F3F3E2',
        '#F3F3E2',
    ],
    [SOURCE_PICO_SRGB]: ['#F1EAD4', '#F2EAD3', '#F2EBD4', '#F2EBD4', '#F3EBD4'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EFEAD5',
        '#EFEAD4',
        '#F0EBD5',
        '#F0EBD5',
        '#F1EBD5',
    ],
};

export const icePink = {
    id: 'ice-pink',
    name: 'ice pink',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#F0D7D3',
        '#F0D7D2',
        '#EFD6D1',
        '#EFD6D1',
        '#F0D8D3',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#F8E9EB',
        '#F8E9EB',
        '#F7E9EB',
        '#F7E9EB',
        '#F7E9EB',
    ],
    [SOURCE_PICO_SRGB]: ['#F2D7CD', '#F2D7CD', '#F3D7CF', '#F3D8CF', '#F3D7CF'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EBD7CE',
        '#EBD7CE',
        '#EBD7CF',
        '#ECD8CF',
        '#EBD7CF',
    ],
};

export const indigo = {
    id: 'indigo',
    name: 'indigo',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#3A3542',
        '#3A3542',
        '#393542',
        '#3A3643',
        '#3A3543',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#393A48',
        '#3A3B48',
        '#3A3A48',
        '#3A3B48',
        '#3A3A48',
    ],
    [SOURCE_PICO_SRGB]: ['#483E44', '#4A3F44', '#473C44', '#493E46', '#473D45'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#453E44',
        '#473F43',
        '#443C44',
        '#473E46',
        '#453D45',
    ],
};

export const jade = {
    id: 'jade',
    name: 'jade',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#00937E',
        '#00947D',
        '#00937D',
        '#00947D',
        '#00947E',
    ],
    [SOURCE_PICO_SRGB]: ['#20A07C', '#169E7B', '#18A07B', '#18A07C', '#1AA07B'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#5EA07E',
        '#5B9E7C',
        '#5CA07C',
        '#5CA07E',
        '#5CA07D',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#1F9285', '#239286', '#219285'],
};

export const kerryGreen = {
    id: 'kerry-green',
    name: 'kerry green',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#259A2D',
        '#269A2D',
        '#259A2D',
        '#279B2E',
        '#289B2D',
    ],
    [SOURCE_PICO_SRGB]: ['#4BA813', '#4AA80E', '#4AA805', '#4AA811', '#4BA815'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#70A829',
        '#6FA827',
        '#70A824',
        '#6FA828',
        '#70A82A',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#499150', '#499150', '#49904F'],
};

export const khaki = {
    id: 'khaki',
    name: 'khaki',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#C4B092',
        '#C4B092',
        '#C4B092',
        '#C4B092',
        '#C4B093',
    ],
    [SOURCE_PICO_SRGB]: ['#CFB68C', '#D0B68E', '#D0B78E', '#D1B78F', '#D0B78E'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#C8B68E',
        '#C9B68F',
        '#C9B790',
        '#CAB791',
        '#CAB790',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#C1B297', '#C2B297', '#C1B397'],
};

export const kingfisher = {
    id: 'kingfisher',
    name: 'kingfisher',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#007C97',
        '#007C98',
        '#007D98',
        '#007D98',
        '#007D98',
    ],
    [SOURCE_PICO_SRGB]: ['#07839A', '#00849A', '#0A849A', '#0B849B', '#078499'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#488399',
        '#488499',
        '#4A8499',
        '#4A849A',
        '#498499',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#006175', '#006175', '#006174'],
};

export const lagoonBlue = {
    id: 'lagoon-blue',
    name: 'lagoon blue',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#00A1C8',
        '#00A2C9',
        '#00A2C9',
        '#00A1C9',
        '#00A1C9',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#00A6C6',
        '#00A6C6',
        '#00A6C6',
        '#00A6C6',
        '#00A6C6',
    ],
    [SOURCE_PICO_SRGB]: ['#00A8CC', '#00A8CB', '#00A8CB', '#00A8CA', '#00A8CB'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#48A8CA',
        '#49A8CA',
        '#49A8CA',
        '#47A8C9',
        '#47A8CA',
    ],
};

export const lavender = {
    id: 'lavender',
    name: 'lavender',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#CEC4E1',
        '#CEC4E1',
        '#CEC4E1',
        '#CEC4E1',
        '#CEC4E1',
    ],
    [SOURCE_PICO_SRGB]: ['#D1C6DE', '#D1C6DD', '#D1C7DE', '#D2C7DE', '#D2C7DE'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#CEC6DD',
        '#CEC6DC',
        '#CEC7DD',
        '#CFC7DD',
        '#CFC7DD',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#BEACDC', '#BEACDC', '#BEABDC'],
};

export const leafGreen = {
    id: 'leaf-green',
    name: 'leaf green',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#008A48',
        '#008947',
        '#008947',
        '#008A48',
        '#008A47',
    ],
    [SOURCE_PICO_SRGB]: ['#2F953F', '#32963E', '#2E963F', '#2F963D', '#2F963F'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#5C9545',
        '#5D9645',
        '#5C9646',
        '#5C9643',
        '#5C9645',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#0F8757', '#0E8958', '#148958'],
};

export const lightBlueGrey = {
    id: 'light-blue-grey',
    name: 'light blue grey',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#A3B9C4',
        '#A3B9C4',
        '#A3B9C4',
        '#A3B9C4',
        '#A3B9C4',
    ],
    [SOURCE_PICO_SRGB]: ['#A7BCBF', '#A7BBBF', '#A8BCC0', '#A8BBBF', '#A7BBBE'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#ADBCBF',
        '#ADBBBF',
        '#AEBCC0',
        '#AEBBBF',
        '#ADBBBE',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#96ABB5', '#96ABB5', '#96ABB5'],
};

export const lightDoveGrey = {
    id: 'light-dove-grey',
    name: 'light dove grey',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D7D6D5',
        '#D7D6D5',
        '#D7D6D5',
        '#D6D6D5',
        '#D6D6D5',
    ],
    [SOURCE_PICO_SRGB]: ['#DAD5CE', '#DAD6CE', '#DAD5CE', '#DAD5CE', '#DAD5CE'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D9D5CE',
        '#D9D6CE',
        '#D9D5CE',
        '#D9D5CE',
        '#D9D5CE',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#CECDCF', '#CFCDD0', '#CECED0'],
};

export const lightEmerald = {
    id: 'light-emerald',
    name: 'light emerald',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#008660',
        '#008760',
        '#00875F',
        '#008660',
        '#008660',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#00896E',
        '#00896D',
        '#00896D',
        '#008A6E',
        '#008A6D',
    ],
    [SOURCE_PICO_SRGB]: ['#33935F', '#31935F', '#33945F', '#339361', '#349360'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#5C9362',
        '#5B9362',
        '#5C9462',
        '#5C9364',
        '#5C9363',
    ],
};

export const lightGrey = {
    id: 'light-grey',
    name: 'light grey',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#B6B8B8',
        '#B5B8B8',
        '#B5B8B8',
        '#B6B9B9',
        '#B7BABA',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#C3C5C9',
        '#C4C5C9',
        '#C5C5C9',
        '#C4C5C9',
        '#C4C6C9',
    ],
    [SOURCE_PICO_SRGB]: ['#B9B9B4', '#B9B9B4', '#B9BAB4', '#BABAB4', '#B9BAB4'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#B9B9B4',
        '#B9B9B4',
        '#B9BAB5',
        '#BABAB4',
        '#B9BAB4',
    ],
};

export const lightOlive = {
    id: 'light-olive',
    name: 'light olive',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#AEA677',
        '#AEA778',
        '#ADA677',
        '#ADA678',
        '#ADA677',
    ],
    [SOURCE_PICO_SRGB]: ['#BCB174', '#BCB174', '#BBB174', '#BCB073', '#BBB074'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#B9B177',
        '#B9B178',
        '#B8B178',
        '#B9B077',
        '#B8B077',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#A6A583', '#A5A382', '#A6A583'],
};

export const lightPeach = {
    id: 'light-peach',
    name: 'light peach',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#ECD4BF',
        '#ECD4C0',
        '#EDD4BF',
        '#ECD4BF',
        '#ECD4BF',
    ],
    [SOURCE_PICO_SRGB]: ['#F8DAB9', '#F8DAB9', '#F8DAB9', '#F9DAB9', '#F9DAB9'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F0DABB',
        '#F0DABA',
        '#F0DABB',
        '#F0DABB',
        '#F1DABB',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F7E6CF', '#F8E7D0', '#F8E6D0'],
};

export const lightSage = {
    id: 'light-sage',
    name: 'light sage',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#CAC5A9',
        '#CAC5A9',
        '#CAC5A9',
        '#CBC6A9',
        '#CAC5A9',
    ],
    [SOURCE_PICO_SRGB]: ['#D3CCA6', '#D4CCA6', '#D4CDA6', '#D5CCA8', '#D5CCA6'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D1CCA8',
        '#D2CCA7',
        '#D2CDA8',
        '#D3CCAA',
        '#D3CCA8',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#D1CEBA', '#D2CDBA', '#D1CEBA'],
};

export const lilac = {
    id: 'lilac',
    name: 'lilac',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#DCCBD6',
        '#DCCAD5',
        '#DCCAD5',
        '#DCCBD5',
        '#DCCBD5',
    ],
    [SOURCE_PICO_SRGB]: ['#E5CECF', '#E5CDCE', '#E5CECE', '#E6CECF', '#E6CDCF'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DECECF',
        '#DECDCE',
        '#DFCECE',
        '#DFCECF',
        '#DFCDCE',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#E7CBD9', '#E8CCDA', '#E8CBDA'],
};

export const lime = {
    id: 'lime',
    name: 'lime',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#CCD06F',
        '#CCD06E',
        '#CCCF6D',
        '#CCCF6D',
        '#CBCF6D',
    ],
    [SOURCE_PICO_SRGB]: ['#D8DA68', '#D8D968', '#D7D969', '#D7D967', '#D9D96A'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D9DA70',
        '#D8D970',
        '#D8D970',
        '#D8D96F',
        '#D9D971',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#CDCF63', '#CDCF64', '#CDCF63'],
};

export const lizardGrey = {
    id: 'lizard-grey',
    name: 'lizard grey',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#B7AA97',
        '#B6AA96',
        '#B5AA95',
        '#B6AA95',
        '#B6AB96',
    ],
    [SOURCE_PICO_SRGB]: ['#C1B192', '#C0B092', '#C1B193', '#C2B293', '#C3B292'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#BDB194',
        '#BCB093',
        '#BDB194',
        '#BEB295',
        '#BEB294',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#B1AA9B', '#B1AA9B', '#B0AA9B'],
};

export const lobelia = {
    id: 'lobelia',
    name: 'lobelia',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#4B2F89',
        '#4B2F89',
        '#4B3089',
        '#4B318A',
        '#4B308A',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#514B8B',
        '#514C8C',
        '#514B8B',
        '#524B8C',
        '#514B8C',
    ],
    [SOURCE_PICO_SRGB]: ['#493C84', '#4B3C84', '#4A3B85', '#493C85', '#4C3D86'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#463C82',
        '#473C82',
        '#463B83',
        '#453C83',
        '#483D84',
    ],
};

export const magenta = {
    id: 'magenta',
    name: 'magenta',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D0377E',
        '#D0377E',
        '#D1387F',
        '#D1397F',
        '#D1397F',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#CC3366',
        '#CC3366',
        '#CC3266',
        '#CC3266',
        '#CC3366',
    ],
    [SOURCE_PICO_SRGB]: ['#FD667F', '#FD6580', '#FD677F', '#FD6780', '#FD6781'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DF667E',
        '#DF657F',
        '#DF677E',
        '#DF677F',
        '#DF6780',
    ],
};

export const midPeach = {
    id: 'mid-peach',
    name: 'mid peach',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#EBDABA',
        '#EBDBBB',
        '#EBDBBA',
        '#EBDABB',
        '#EBDABA',
    ],
    [SOURCE_PICO_SRGB]: ['#F7E2B6', '#F8E2B5', '#F8E1B6', '#F7E1B6', '#F8E1B5'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F2E2B8',
        '#F2E2B7',
        '#F2E1B8',
        '#F1E1B8',
        '#F2E1B7',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F8E3CF', '#FAE4D0', '#F8E4CE'],
};

export const mintGreen = {
    id: 'mint-green',
    name: 'mint green',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#C7E395',
        '#C6E295',
        '#C7E295',
        '#C7E295',
        '#C7E396',
    ],
    [SOURCE_PICO_SRGB]: ['#CAE78F', '#C9E78F', '#CAE790', '#C9E78F', '#C9E68F'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D2E794',
        '#D2E794',
        '#D2E795',
        '#D2E794',
        '#D2E694',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#B0DE8F', '#B1DE90', '#B0DD8F'],
};

export const mole = {
    id: 'mole',
    name: 'mole',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#736D70',
        '#736E71',
        '#736F72',
        '#726F71',
        '#726E71',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#6C6A6D',
        '#6C696C',
        '#6C696D',
        '#6D6A6D',
        '#6D696D',
    ],
    [SOURCE_PICO_SRGB]: ['#847A70', '#827B70', '#817971', '#817970', '#837A6D'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#817A71',
        '#807B70',
        '#7F7971',
        '#7F7970',
        '#817A6E',
    ],
};

export const mossGreen = {
    id: 'moss-green',
    name: 'moss green',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#7B7F3F',
        '#7B8040',
        '#7B7F40',
        '#7B803F',
        '#7C803F',
    ],
    [SOURCE_PICO_SRGB]: ['#8B8C40', '#8B8C42', '#8E8D43', '#8B8B40', '#8A8A40'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#8B8C46',
        '#8B8C47',
        '#8D8D48',
        '#8B8B46',
        '#8A8A45',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#646D47', '#656D48', '#666F4A'],
};

export const mushroom = {
    id: 'mushroom',
    name: 'mushroom',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#C29177',
        '#C29176',
        '#C29176',
        '#C39277',
        '#C29277',
    ],
    [SOURCE_PICO_SRGB]: ['#D6A075', '#D6A074', '#D5A075', '#D6A075', '#D59F74'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#C8A077',
        '#C9A077',
        '#C8A077',
        '#C8A078',
        '#C89F77',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#A6897D', '#A68B7D', '#A5897D'],
};

export const muskPink = {
    id: 'musk-pink',
    name: 'musk pink',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#BE5068',
        '#BE5269',
        '#BF5269',
        '#BE5269',
        '#BE5369',
    ],
    [SOURCE_PICO_SRGB]: ['#DA6F69', '#DA7068', '#DA6F69', '#DA7069', '#D96F68'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#C46F69',
        '#C37069',
        '#C36F69',
        '#C37069',
        '#C36F68',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#B2515E', '#B2535F', '#B2525E'],
};

export const mustard = {
    id: 'mustard',
    name: 'mustard',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#DBAE33',
        '#DBAE34',
        '#DBAF33',
        '#DCAF34',
        '#DBAF33',
    ],
    [SOURCE_PICO_SRGB]: ['#EEBE33', '#EEBE35', '#EEBE35', '#EDBE36', '#EEBE35'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E1BE41',
        '#E2BE42',
        '#E1BE42',
        '#E1BE43',
        '#E2BE42',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#D6AA4F', '#D6AB4F', '#D6AB4F'],
};

export const navy = {
    id: 'navy',
    name: 'navy',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#2E354F',
        '#2E354E',
        '#2E354F',
        '#2E3650',
        '#2E3650',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#323B4E',
        '#323B4E',
        '#313B4D',
        '#323B4E',
        '#323B4E',
    ],
    [SOURCE_PICO_SRGB]: ['#3F3A50', '#3D3751', '#3C3750', '#3D384F', '#3E3950'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#3D3A4F',
        '#3C3750',
        '#3B374F',
        '#3C384E',
        '#3D394F',
    ],
};

export const oatmeal = {
    id: 'oatmeal',
    name: 'oatmeal',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#EEE4D4',
        '#EEE4D4',
        '#EEE5D5',
        '#EEE5D5',
        '#EEE4D5',
    ],
    [SOURCE_PICO_SRGB]: ['#F4E6CD', '#F4E6CD', '#F4E6CD', '#F4E6CD', '#F4E6CD'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F1E6CE',
        '#F0E6CE',
        '#F1E6CE',
        '#F0E6CE',
        '#F1E6CE',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F2E1CA', '#F2E2CA', '#F2E2CB'],
};

export const oldGold = {
    id: 'old-gold',
    name: 'old gold',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D0A469',
        '#D1A469',
        '#D1A469',
        '#D1A569',
        '#D1A569',
    ],
    [SOURCE_PICO_SRGB]: ['#E2B067', '#E1B066', '#E1B065', '#E2B067', '#E2B067'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D5B06C',
        '#D5B06A',
        '#D5B06A',
        '#D6B06B',
        '#D6B06B',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#D8B175', '#D7B073', '#D6AF76'],
};

export const orange = {
    id: 'orange',
    name: 'orange',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#FF740F',
        '#FF7410',
        '#FF7410',
        '#FF740C',
        '#FF740A',
    ],
    [SOURCE_PICO_SRGB]: ['#FF7633', '#FF7636', '#FF7735', '#FF7738', '#FF7636'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EA7638',
        '#EB763A',
        '#EB7739',
        '#EB773C',
        '#EB763A',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F65F23', '#F75D23', '#F65E23'],
};

export const oxfordBlue = {
    id: 'oxford-blue',
    name: 'oxford blue',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#155F90',
        '#145E8F',
        '#145D8E',
        '#115C8E',
        '#105B8C',
    ],
    [SOURCE_PICO_SRGB]: ['#336490', '#33628E', '#30618F', '#32628E', '#34608C'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#45648E',
        '#45628D',
        '#43618D',
        '#44628C',
        '#44608B',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#3F6C96', '#3F6A95', '#406B97'],
};

export const oyster = {
    id: 'oyster',
    name: 'oyster',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#E9E7DD',
        '#E9E7DD',
        '#E9E7DC',
        '#E8E7DC',
        '#E9E7DD',
    ],
    [SOURCE_PICO_SRGB]: ['#F2ECDA', '#F2ECDA', '#F2ECDA', '#F1EBD9', '#F2EBDA'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F0ECDA',
        '#F0ECDB',
        '#F0ECDB',
        '#F0EBDA',
        '#F0EBDA',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F5EADD', '#F6E9DD', '#F6EADD'],
};

export const pastelAqua = {
    id: 'pastel-aqua',
    name: 'pastel aqua',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#C1D6E5',
        '#C1D6E5',
        '#C0D6E4',
        '#C1D6E5',
        '#C1D6E4',
    ],
    [SOURCE_PICO_SRGB]: ['#C4D7DF', '#C5D8DF', '#C5D7DF', '#C4D7DF', '#C4D7DE'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#CAD7DF',
        '#CAD8DF',
        '#CAD7DF',
        '#C9D7DE',
        '#CAD7DE',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#BFDDEA', '#C0DDEA', '#C0DCEA'],
};

export const pastelJade = {
    id: 'pastel-jade',
    name: 'pastel jade',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#98C298',
        '#97C198',
        '#96C299',
        '#97C29A',
        '#97C299',
    ],
    [SOURCE_PICO_SRGB]: ['#A1CA94', '#A2CA95', '#A3CB96', '#A2CA95', '#A3CA94'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#AECA97',
        '#AFCA98',
        '#AFCB98',
        '#AECA97',
        '#AFCA97',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#70BC9A', '#71BC9A', '#70BC9A'],
};

export const pastelRose = {
    id: 'pastel-rose',
    name: 'pastel rose',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#ECE0DB',
        '#ECDFDA',
        '#ECDEDA',
        '#EDDFDB',
        '#EDE0DB',
    ],
    [SOURCE_PICO_SRGB]: ['#F4E2D3', '#F4E1D3', '#F5E2D3', '#F5E2D4', '#F5E2D3'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EFE2D4',
        '#EFE1D4',
        '#F0E2D4',
        '#F0E2D4',
        '#F0E2D4',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FDD5D6', '#FED6D7', '#FED5D6'],
};

export const peach = {
    id: 'peach',
    name: 'peach',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#ECCC9B',
        '#ECCC9B',
        '#ECCC9B',
        '#ECCC9B',
        '#ECCC9C',
    ],
    [SOURCE_PICO_SRGB]: ['#F9D497', '#F9D497', '#F9D496', '#F9D497', '#F9D496'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EFD49A',
        '#F0D49A',
        '#EFD49A',
        '#EFD49A',
        '#EFD499',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FCCEA7', '#FCCEA6', '#FCCEA8'],
};

export const peacock = {
    id: 'peacock',
    name: 'peacock',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#4DA9B2',
        '#4EA9B2',
        '#50AAB3',
        '#50AAB2',
        '#50A9B2',
    ],
    [SOURCE_PICO_SRGB]: ['#59B0B1', '#59B0B1', '#59B0B1', '#58B0B0', '#59B0B0'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#7AB0B1',
        '#7AB0B1',
        '#7AB0B1',
        '#7AB0B0',
        '#7AB0B0',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#3E9AA2', '#3D9AA1', '#409AA3'],
};

export const pineGreen = {
    id: 'pine-green',
    name: 'pine green',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#245846',
        '#235845',
        '#235845',
        '#235744',
        '#245846',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#295249',
        '#2A5149',
        '#295249',
        '#285148',
        '#295249',
    ],
    [SOURCE_PICO_SRGB]: ['#3A6049', '#3D6249', '#3D5F4A', '#3A5F47', '#396147'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#47604A',
        '#4A624A',
        '#495F4B',
        '#475F48',
        '#476148',
    ],
};

export const pinkBeige = {
    id: 'pink-beige',
    name: 'pink beige',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#EDE4DD',
        '#EDE4DC',
        '#ECE4DC',
        '#EEE6DE',
        '#EEE6DE',
    ],
    [SOURCE_PICO_SRGB]: ['#F2E5D5', '#F3E6D5', '#F3E6D5', '#F3E6D6', '#F3E7D6'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EFE5D6',
        '#EFE6D6',
        '#EFE6D6',
        '#F0E6D7',
        '#F0E7D7',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F3E5D4', '#F3E4D4', '#F3E5D5'],
};

export const plum = {
    id: 'plum',
    name: 'plum',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#91588E',
        '#91598F',
        '#91598F',
        '#92588F',
        '#92598F',
    ],
    [SOURCE_PICO_SRGB]: ['#A56A8C', '#A3698C', '#A3688B', '#A56A8D', '#A4688B'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#976A8B',
        '#95698B',
        '#95688A',
        '#976A8B',
        '#96688A',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#724266', '#724366', '#714265'],
};

export const poppy = {
    id: 'poppy',
    name: 'poppy',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#DA3547',
        '#DB3547',
        '#DB3547',
        '#DB3547',
        '#DB3547',
    ],
    [SOURCE_PICO_SRGB]: ['#FF674A', '#FF684A', '#FF6749', '#FF6749', '#FF684B'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E2674C',
        '#E2684C',
        '#E3674A',
        '#E3674A',
        '#E3684D',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#BA2D38', '#B92D38', '#BA2E38'],
};

export const powderBlue = {
    id: 'powder-blue',
    name: 'powder blue',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#CCD8E5',
        '#CBD8E5',
        '#CBD8E5',
        '#CBD8E5',
        '#CBD8E5',
    ],
    [SOURCE_PICO_SRGB]: ['#D0DADF', '#D0DAE0', '#D0DADF', '#D1DAE0', '#D1DAE0'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D3DADF',
        '#D3DADF',
        '#D3DADF',
        '#D4DADF',
        '#D4DADF',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#B8CEE7', '#B8CFE8', '#B9CEE8'],
};

export const powderPink = {
    id: 'powder-pink',
    name: 'powder pink',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#EDE3DF',
        '#ECE3DE',
        '#ECE3DE',
        '#EDE3DF',
        '#ECE3DF',
    ],
    [SOURCE_PICO_SRGB]: ['#F3E4D7', '#F3E4D7', '#F3E4D7', '#F3E4D7', '#F4E5D8'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EFE4D8',
        '#EFE4D8',
        '#EFE4D8',
        '#EFE4D7',
        '#F0E5D9',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F7E7E3', '#F6E8E2', '#F7E8E3'],
};

export const primrose = {
    id: 'primrose',
    name: 'primrose',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#EBE9BD',
        '#ECE9BD',
        '#ECE9BD',
        '#ECE9BD',
        '#ECE9BD',
    ],
    [SOURCE_PICO_SRGB]: ['#F5EDB9', '#F5EDB9', '#F5EEB9', '#F5EDB9', '#F5EDB9'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F2EDBC',
        '#F3EDBB',
        '#F3EEBC',
        '#F3EDBC',
        '#F3EDBC',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F2F0C4', '#F2F1C5', '#F1F1C4'],
};

export const raspberry = {
    id: 'raspberry',
    name: 'raspberry',
    seasons: [WINTER, SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#CB3775',
        '#CB3775',
        '#CB3775',
        '#CB3875',
        '#CB3875',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#BF3A60',
        '#BF3B60',
        '#BE3A5F',
        '#BF3A60',
        '#BF3B60',
    ],
    [SOURCE_PICO_SRGB]: ['#F26574', '#F26576', '#F36675', '#F26576', '#F26575'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D66573',
        '#D66575',
        '#D76675',
        '#D66575',
        '#D66574',
    ],
};

export const red = {
    id: 'red',
    name: 'red',
    seasons: [],
    [SOURCE_COLOR_MUSE_CARD]: ['#FF0000'],
    [SOURCE_PICO_SRGB]: ['#FF0000'],
    [SOURCE_PICO_ADOBE_RGB]: ['#FF0000'],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FF0000'],
};

export const rose = {
    id: 'rose',
    name: 'rose',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#F4B3C3',
        '#F4B2C3',
        '#F4B2C2',
        '#F4B3C3',
        '#F4B3C3',
    ],
    [SOURCE_PICO_SRGB]: ['#FFB9BC', '#FFB8BD', '#FFBABE', '#FFB9BD', '#FFB9BD'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EEB9BC',
        '#EEB8BD',
        '#EEBABE',
        '#EEB9BD',
        '#EEB9BD',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F07AA0', '#F07BA1', '#F179A0'],
};

export const roseBrown = {
    id: 'rose-brown',
    name: 'rose brown',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#754A42',
        '#754A42',
        '#744A42',
        '#754B43',
        '#754C43',
    ],
    [SOURCE_PICO_SRGB]: ['#885B48', '#8B5C4C', '#895F4B', '#8A5D49', '#895D4B'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#7D5B49',
        '#805C4D',
        '#7F5F4C',
        '#7F5D4A',
        '#7F5D4C',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#765A57', '#755B57', '#775B58'],
};

export const roseMadder = {
    id: 'rose-madder',
    name: 'rose madder',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D76F7F',
        '#D77080',
        '#D7707F',
        '#D7707F',
        '#D87181',
    ],
    [SOURCE_PICO_SRGB]: ['#F3897C', '#F48A7D', '#F48A7F', '#F48A7E', '#F38A7F'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DC897C',
        '#DC8A7D',
        '#DC8A80',
        '#DC8A7F',
        '#DC8A7F',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#B84E62', '#B85163', '#B84F62'],
};

export const rosewood = {
    id: 'rosewood',
    name: 'rosewood',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D8A092',
        '#D7A091',
        '#D79F91',
        '#D8A092',
        '#D79F91',
    ],
    [SOURCE_PICO_SRGB]: ['#EBAC8E', '#EAAC8E', '#EAAD8E', '#EBAC8C', '#EBAC8D'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DBAC8F',
        '#DBAC8F',
        '#DBAD8F',
        '#DBAC8E',
        '#DBAC8E',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#DCA196', '#DC9F95', '#DDA195'],
};

export const royalBlue = {
    id: 'royal-blue',
    name: 'royal blue',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#004AA1',
        '#004AA1',
        '#004AA2',
        '#004BA2',
        '#004BA2',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#395696',
        '#395696',
        '#385595',
        '#385595',
        '#385596',
    ],
    [SOURCE_PICO_SRGB]: ['#1B4AA6', '#1848A6', '#1A4AA7', '#204AA6', '#1A4AA6'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#2E4AA3',
        '#2C48A3',
        '#2E4AA5',
        '#304AA3',
        '#2E4AA4',
    ],
};

export const royalPurple = {
    id: 'royal-purple',
    name: 'royal purple',
    seasons: [AUTUMN, WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#5B2F77',
        '#5B2F76',
        '#5B3077',
        '#5C3078',
        '#5D3178',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#4D4175',
        '#4E4075',
        '#4E4175',
        '#4E4175',
        '#4E4075',
    ],
    [SOURCE_PICO_SRGB]: ['#634072', '#614372', '#624173', '#623F73', '#603F72'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#5B4071',
        '#594370',
        '#5A4171',
        '#5A3F71',
        '#583F71',
    ],
};

export const rust = {
    id: 'rust',
    name: 'rust',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#B9533F',
        '#BA523F',
        '#BA5340',
        '#BA5440',
        '#BA5441',
    ],
    [SOURCE_PICO_SRGB]: ['#CE6645', '#CE6644', '#CD6643', '#CE6746', '#CE6845'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#B86647',
        '#B86646',
        '#B76644',
        '#B86748',
        '#B96847',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#A94A39', '#A94939', '#A84A39'],
};

export const saffron = {
    id: 'saffron',
    name: 'saffron',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#FF960F',
        '#FF9611',
        '#FF9712',
        '#FF9614',
        '#FF9714',
    ],
    [SOURCE_PICO_SRGB]: ['#FF9931', '#FF9932', '#FF9834', '#FF9831', '#FF9831'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F0993B',
        '#F0993B',
        '#F0983D',
        '#F0983A',
        '#F0983A',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FD9F3A', '#FE9F3B', '#FE9E38'],
};

export const salmon = {
    id: 'salmon',
    name: 'salmon',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#F9BDAD',
        '#F9BDAD',
        '#F8BDAC',
        '#F9BDAD',
        '#F9BEAD',
    ],
    [SOURCE_PICO_SRGB]: ['#FFBFA8', '#FEBFA8', '#FEBFA8', '#FEBFA7', '#FEBFA8'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EFBFA9',
        '#EEBFA9',
        '#EFBFA9',
        '#EEBFA8',
        '#EFBFA9',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FFBEB0', '#FFBEB0', '#FEBEAF'],
};

export const scarlet = {
    id: 'scarlet',
    name: 'scarlet',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D52839',
        '#D5293A',
        '#D5293A',
        '#D5293A',
        '#D5293A',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#BD2B37',
        '#BD2B36',
        '#BD2B36',
        '#BD2C37',
        '#BD2C36',
    ],
    [SOURCE_PICO_SRGB]: ['#FC5F39', '#FC6139', '#FD6138', '#FB6038', '#FC6138'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DE5F3B',
        '#DE613C',
        '#DE613B',
        '#DD603B',
        '#DE613A',
    ],
};

export const seaGreen = {
    id: 'sea-green',
    name: 'sea green',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#007A84',
        '#007A84',
        '#007A84',
        '#007A84',
        '#007A84',
    ],
    [SOURCE_PICO_SRGB]: ['#1C8385', '#248284', '#1D8285', '#208285', '#228384'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#4C8385',
        '#4E8284',
        '#4C8285',
        '#4D8285',
        '#4E8384',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#0F6878', '#0E6878', '#0B6778'],
};

export const shellPink = {
    id: 'shell-pink',
    name: 'shell pink',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#EEE2DC',
        '#EFE2DC',
        '#EFE2DB',
        '#EFE2DB',
        '#EFE2DC',
    ],
    [SOURCE_PICO_SRGB]: ['#F5E3D6', '#F4E3D5', '#F4E3D6', '#F3E0D6', '#F4E3D5'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F0E3D7',
        '#F0E3D6',
        '#F0E3D7',
        '#EEE0D6',
        '#F0E3D6',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FFE0D4', '#FFDFD4', '#FFDFD3'],
};

export const shockingPink = {
    id: 'shocking-pink',
    name: 'shocking pink',
    seasons: [SPRING, WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#F285AE',
        '#F385AF',
        '#F285AE',
        '#F385AE',
        '#F385AE',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#E26B94',
        '#E26B94',
        '#E26B94',
        '#E26B94',
        '#E26B94',
    ],
    [SOURCE_PICO_SRGB]: ['#FF8EA8', '#FF8EA8', '#FF8EA8', '#FF8EA8', '#FF8DA8'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E68EA7',
        '#E68EA7',
        '#E68EA7',
        '#E68EA7',
        '#E68DA7',
    ],
};

export const silver = {
    id: 'silver',
    name: 'silver',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#DBD9CC',
        '#DBD9CC',
        '#DCD9CD',
        '#DDDACE',
        '#DDDACE',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#DDE1E5',
        '#DEE1E5',
        '#DEE1E5',
        '#DEE1E5',
        '#DEE1E5',
    ],
    [SOURCE_PICO_SRGB]: ['#E0DACA', '#E0DBCA', '#E0DBCA', '#E2DBCB', '#E2DBCC'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#DFDACB',
        '#DEDBCB',
        '#DFDBCB',
        '#E0DBCC',
        '#E0DBCC',
    ],
};

export const skyBlue = {
    id: 'sky-blue',
    name: 'sky blue',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#90B2D5',
        '#8FB1D5',
        '#8FB1D5',
        '#90B1D5',
        '#90B1D5',
    ],
    [SOURCE_PICO_SRGB]: ['#98B5D3', '#99B6D3', '#9AB7D3', '#9AB6D3', '#98B6D3'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#A1B5D2',
        '#A2B6D2',
        '#A3B7D2',
        '#A3B6D2',
        '#A1B6D1',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#85A8D4', '#86A9D4', '#87A8D4'],
};

export const smokedGrape = {
    id: 'smoked-grape',
    name: 'smoked grape',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#513262',
        '#513262',
        '#513262',
        '#513263',
        '#513163',
    ],
    [SOURCE_PICO_SRGB]: ['#59405E', '#59415E', '#593F5F', '#594160', '#594260'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#53405D',
        '#53415D',
        '#533F5E',
        '#53415F',
        '#53425F',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#4D404E', '#4D4150', '#4D404E'],
};

export const softWhite = {
    id: 'soft-white',
    name: 'soft white',
    seasons: [SUMMER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#E9E9DE',
        '#EAEADF',
        '#EAEADF',
        '#EBEADF',
        '#EAEADF',
    ],
    [SOURCE_PICO_SRGB]: ['#EFEBDA', '#F0EBDA', '#EFEBDA', '#F0EBDA', '#F0EBDB'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EEEBDB',
        '#EEEBDB',
        '#EEEBDB',
        '#EFEBDB',
        '#EFEBDB',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#F5F5F3', '#F5F5F3', '#F6F5F3'],
};

export const stone = {
    id: 'stone',
    name: 'stone',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#D1C8B7',
        '#D1C8B6',
        '#D1C8B6',
        '#D1C8B6',
        '#D2C8B6',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#DAD1BF',
        '#DAD0BF',
        '#DAD1BF',
        '#D9D1BF',
        '#D9D0BE',
    ],
    [SOURCE_PICO_SRGB]: ['#D6CAB0', '#D6CAB0', '#D8CBB1', '#D7CAB1', '#D7CAB1'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#D3CAB1',
        '#D3CAB2',
        '#D4CBB2',
        '#D3CAB2',
        '#D3CAB2',
    ],
};

export const tan = {
    id: 'tan',
    name: 'tan',
    seasons: [SPRING, AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#9A623F',
        '#9B623F',
        '#9C623F',
        '#9B6240',
        '#9C6240',
    ],
    [SOURCE_PICO_SRGB]: ['#B07446', '#B17445', '#B17445', '#B17445', '#B17546'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#A27448',
        '#A37447',
        '#A27447',
        '#A27448',
        '#A37549',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#885E46', '#885D46', '#885D46'],
};

export const tangerine = {
    id: 'tangerine',
    name: 'tangerine',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#FC9960',
        '#FC9960',
        '#FC9961',
        '#FC9961',
        '#FC9A61',
    ],
    [SOURCE_PICO_SRGB]: ['#FF9D64', '#FF9E65', '#FF9D63', '#FF9E63', '#FF9D63'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EF9D67',
        '#EF9E68',
        '#EF9D67',
        '#EF9E66',
        '#EF9D67',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FE9F7B', '#FE9E7A', '#FE9C79'],
};

export const terracotta = {
    id: 'terracotta',
    name: 'terracotta',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#DC5B45',
        '#DC5B45',
        '#DC5A46',
        '#DC5B46',
        '#DC5B46',
    ],
    [SOURCE_PICO_SRGB]: ['#FB7D4B', '#FB7D4B', '#FB7E48', '#FB7E4B', '#FB7D4A'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#E07D4E',
        '#E07D4E',
        '#E07E4B',
        '#E17E4E',
        '#E07D4D',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#BD4531', '#BD4632', '#BE4632'],
};

export const turquoise = {
    id: 'turquoise',
    name: 'turquoise',
    seasons: [SPRING, WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#009FCD',
        '#009FCD',
        '#009FCD',
        '#009FCD',
        '#009FCD',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#008EC6',
        '#008EC5',
        '#008EC6',
        '#008EC6',
        '#008DC5',
    ],
    [SOURCE_PICO_SRGB]: ['#00A5CF', '#00A5CE', '#00A5CF', '#00A4CF', '#00A4CF'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#50A5CD',
        '#51A5CD',
        '#50A5CD',
        '#51A4CE',
        '#50A4CD',
    ],
};

export const violet = {
    id: 'violet',
    name: 'violet',
    seasons: [SPRING],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#814EA9',
        '#814EAA',
        '#814FAA',
        '#814EAA',
        '#824FAA',
    ],
    [SOURCE_PICO_SRGB]: ['#8A5BA7', '#8A5BA7', '#895BA6', '#8A5CA7', '#895CA7'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#7E5BA5',
        '#7F5BA5',
        '#7E5BA3',
        '#7F5CA5',
        '#7F5CA4',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#775A94', '#785B95', '#785A94'],
};

export const warmNavy = {
    id: 'warm-navy',
    name: 'warm navy',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#223F4A',
        '#24404B',
        '#233F4B',
        '#233F4B',
        '#24404C',
    ],
    [SOURCE_PICO_SRGB]: ['#38454F', '#3A444E', '#3B454E', '#3B474E', '#3A474D'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#3C454F',
        '#3D444E',
        '#3E454D',
        '#3E474E',
        '#3E474D',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#304E64', '#2F4E63', '#2F4D63'],
};

export const white = {
    id: 'white',
    name: 'white',
    seasons: [WINTER],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#EDECE9',
        '#EDECE9',
        '#EDECE9',
        '#EEECEA',
        '#EEEDEA',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: [
        '#F2F0F5',
        '#F2F0F4',
        '#F1F0F5',
        '#F1F1F5',
        '#F2F0F5',
    ],
    [SOURCE_PICO_SRGB]: ['#EEEBE1', '#EEEBE1', '#EEEBE1', '#EFEBE2', '#EEEBE2'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#EDEBE2',
        '#EDEBE2',
        '#EDEBE2',
        '#EEEBE2',
        '#EDEBE2',
    ],
};

export const yellowOchre = {
    id: 'yellow-ochre',
    name: 'yellow ochre',
    seasons: [AUTUMN],
    [SOURCE_COLOR_MUSE_CARD]: [
        '#F1D155',
        '#F1D155',
        '#F1D155',
        '#F1D055',
        '#F1D156',
    ],
    [SOURCE_PICO_SRGB]: ['#FFDC51', '#FFDD50', '#FFDD51', '#FFDC52', '#FFDC50'],
    [SOURCE_PICO_ADOBE_RGB]: [
        '#F7DC5C',
        '#F7DD5C',
        '#F7DD5D',
        '#F7DC5E',
        '#F8DC5C',
    ],
    [SOURCE_COLOR_MUSE_FABRIC]: ['#FFD564', '#FFD563', '#FFD562'],
};
