import { WINTER, SPRING, SUMMER, AUTUMN } from '../constants/seasons';
import {
    WINTER_DESCRIPTORS,
    SPRING_DESCRIPTORS,
    SUMMER_DESCRIPTORS,
    AUTUMN_DESCRIPTORS,
} from '../constants/season-descriptors';

export function getDescriptorsBySeason(season: string): string[] {
    switch (season) {
        case WINTER:
            return WINTER_DESCRIPTORS;
        case SPRING:
            return SPRING_DESCRIPTORS;
        case SUMMER:
            return SUMMER_DESCRIPTORS;
        case AUTUMN:
            return AUTUMN_DESCRIPTORS;
        default:
            return [];
    }
}
