export const GROUP_SHARP_STRAIGHT = 'sharp straight';
export const GROUP_STRAIGHT = 'straight';
export const GROUP_SOFT_STRAIGHT = 'soft straight';
export const GROUP_SOFT_CURVED = 'soft curved';
export const GROUP_FULL_CURVED = 'full curved';

export const BODY_GEOMETRIC_RECTANGLE = 'rectangle';
export const BODY_GEOMETRIC_TRIANGLE = 'triangle';
export const BODY_GEOMETRIC_SQUARE = 'square';
export const BODY_GEOMETRIC_OBLONG = 'oblong';
export const BODY_GEOMETRIC_OVAL = 'oval';
export const BODY_GEOMETRIC_SOFT_SQUARE = 'soft square';
export const BODY_GEOMETRIC_ROUND = 'round';

export const SHARP_STRAIGHT_RECTANGLE = {
    key: 'sharp-straight-rectangle',
    value: 'rectangle',
};
export const SHARP_STRAIGHT_TRIANGLE = {
    key: 'sharp-straight-triangle',
    value: 'triangle',
};
export const STRAIGHT_RECTANGLE = {
    key: 'straight-rectangle',
    value: 'rectangle',
};
export const STRAIGHT_SQUARE = { key: 'straight-square', value: 'square' };
export const STRAIGHT_OBLONG = { key: 'straight-oblong', value: 'oblong' };
export const SOFT_STRAIGHT_OBLONG = {
    key: 'soft-straight-oblong',
    value: 'oblong',
};
export const SOFT_STRAIGHT_OVAL = { key: 'soft-straight-oval', value: 'oval' };
export const SOFT_STRAIGHT_SOFT_SQUARE = {
    key: 'soft-straight-soft-square',
    value: 'soft square',
};
export const SOFT_CURVED_OBLONG = {
    key: 'soft-curved-oblong',
    value: 'oblong',
};
export const SOFT_CURVED_OVAL = { key: 'soft-curved-oval', value: 'oval' };
export const FULL_CURVED_OVAL = { key: 'full-curved-oval', value: 'oval' };
export const FULL_CURVED_ROUND = { key: 'full-curved-round', value: 'round' };
