import { Router, Route } from 'preact-router';
import {
    ROUTE_HOME,
    ROUTE_SEASON,
    ROUTE_COLOR,
    ROUTE_SEASONS,
    ROUTE_KETTLEWELL_COLOR,
    ROUTE_STYLE,
} from '../constants/routes';
import { SeasonView } from './season-view';
import { ColorView } from './color-view';
import { SeasonsView } from './seasons-view';
import { KettlewellColor } from './kettlewell-color';
import { StyleView } from './style-view';
import { JSX } from 'preact';

export function AppRouter(): JSX.Element {
    return (
        <Router>
            <Route path={ROUTE_HOME} component={SeasonsView} />
            <Route path={ROUTE_SEASONS} component={SeasonsView} />
            <Route path={ROUTE_SEASON} component={SeasonView} />
            <Route path={ROUTE_COLOR} component={ColorView} />
            <Route path={ROUTE_KETTLEWELL_COLOR} component={KettlewellColor} />

            <Route path={ROUTE_STYLE} component={StyleView} />
        </Router>
    );
}
