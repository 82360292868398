import {
    FULL_CURVED_OVAL,
    FULL_CURVED_ROUND,
    GROUP_FULL_CURVED,
    GROUP_SHARP_STRAIGHT,
    GROUP_SOFT_CURVED,
    GROUP_SOFT_STRAIGHT,
    GROUP_STRAIGHT,
    SHARP_STRAIGHT_RECTANGLE,
    SHARP_STRAIGHT_TRIANGLE,
    SOFT_CURVED_OBLONG,
    SOFT_CURVED_OVAL,
    SOFT_STRAIGHT_OBLONG,
    SOFT_STRAIGHT_OVAL,
    SOFT_STRAIGHT_SOFT_SQUARE,
    STRAIGHT_OBLONG,
    STRAIGHT_RECTANGLE,
    STRAIGHT_SQUARE,
} from '../../constants/body-geometrics';
import {
    actions,
    SELECTED_BODY_GEOMETRY,
    SELECTED_FACE_SHAPES,
    SELECTED_JAW_ANGLE,
    SELECTED_NECK_LENGTH,
} from '../../state/store';
import { useStore } from '../../state/store-provider';
import { JSX } from 'preact';
import {
    DIAMOND,
    HEART,
    OBLONG,
    OVAL,
    PEAR,
    RECTANGLE,
    ROUND,
    SOFT_SQUARE,
    SQUARE,
    TRIANGLE,
} from '../../constants/face-shapes';
import { ACUTE, MEDIUM, SOFT } from '../../constants/jaw-angles';
import { LONG, SHORT } from '../../constants/neck-lengths';
import { Select } from '../select';

export function BodyGeometricsPage(): JSX.Element {
    const {
        state: {
            selectedBodyGeometry,
            selectedFaceShapes,
            selectedJawAngle,
            selectedNeckLength,
        },
        dispatch,
    } = useStore();

    return (
        <>
            <h3>{'body geometrics'}</h3>
            <h6>{'body shape'}</h6>
            <Select
                onInput={(event: JSX.TargetedEvent<HTMLSelectElement>) =>
                    dispatch(
                        actions.set(
                            SELECTED_BODY_GEOMETRY,
                            event.currentTarget.value
                        )
                    )
                }
            >
                <option selected={true} disabled={true}></option>
                <optgroup label={GROUP_SHARP_STRAIGHT}>
                    <option
                        selected={
                            selectedBodyGeometry ===
                            SHARP_STRAIGHT_RECTANGLE.key
                        }
                        value={SHARP_STRAIGHT_RECTANGLE.key}
                    >
                        {SHARP_STRAIGHT_RECTANGLE.value}
                    </option>
                    <option
                        selected={
                            selectedBodyGeometry === SHARP_STRAIGHT_TRIANGLE.key
                        }
                        value={SHARP_STRAIGHT_TRIANGLE.key}
                    >
                        {SHARP_STRAIGHT_TRIANGLE.value}
                    </option>
                </optgroup>
                <optgroup label={GROUP_STRAIGHT}>
                    <option
                        selected={
                            selectedBodyGeometry === STRAIGHT_RECTANGLE.key
                        }
                        value={STRAIGHT_RECTANGLE.key}
                    >
                        {STRAIGHT_RECTANGLE.value}
                    </option>
                    <option
                        selected={selectedBodyGeometry === STRAIGHT_SQUARE.key}
                        value={STRAIGHT_SQUARE.key}
                    >
                        {STRAIGHT_SQUARE.value}
                    </option>
                    <option
                        selected={selectedBodyGeometry === STRAIGHT_OBLONG.key}
                        value={STRAIGHT_OBLONG.key}
                    >
                        {STRAIGHT_OBLONG.value}
                    </option>
                </optgroup>
                <optgroup label={GROUP_SOFT_STRAIGHT}>
                    <option
                        selected={
                            selectedBodyGeometry === SOFT_STRAIGHT_OBLONG.key
                        }
                        value={SOFT_STRAIGHT_OBLONG.key}
                    >
                        {SOFT_STRAIGHT_OBLONG.value}
                    </option>
                    <option
                        selected={
                            selectedBodyGeometry === SOFT_STRAIGHT_OVAL.key
                        }
                        value={SOFT_STRAIGHT_OVAL.key}
                    >
                        {SOFT_STRAIGHT_OVAL.value}
                    </option>
                    <option
                        selected={
                            selectedBodyGeometry ===
                            SOFT_STRAIGHT_SOFT_SQUARE.key
                        }
                        value={SOFT_STRAIGHT_SOFT_SQUARE.key}
                    >
                        {SOFT_STRAIGHT_SOFT_SQUARE.value}
                    </option>
                </optgroup>
                <optgroup label={GROUP_SOFT_CURVED}>
                    <option
                        selected={
                            selectedBodyGeometry === SOFT_CURVED_OBLONG.key
                        }
                        value={SOFT_CURVED_OBLONG.key}
                    >
                        {SOFT_CURVED_OBLONG.value}
                    </option>
                    <option
                        selected={selectedBodyGeometry === SOFT_CURVED_OVAL.key}
                        value={SOFT_CURVED_OVAL.key}
                    >
                        {SOFT_CURVED_OVAL.value}
                    </option>
                </optgroup>
                <optgroup label={GROUP_FULL_CURVED}>
                    <option
                        selected={selectedBodyGeometry === FULL_CURVED_OVAL.key}
                        value={FULL_CURVED_OVAL.key}
                    >
                        {FULL_CURVED_OVAL.value}
                    </option>
                    <option
                        selected={
                            selectedBodyGeometry === FULL_CURVED_ROUND.key
                        }
                        value={FULL_CURVED_ROUND.key}
                    >
                        {FULL_CURVED_ROUND.value}
                    </option>
                </optgroup>
            </Select>

            <h6>{'face shapes'}</h6>
            <Select
                multiple={true}
                onInput={(event: JSX.TargetedEvent<HTMLSelectElement>) =>
                    dispatch(
                        actions.set(
                            SELECTED_FACE_SHAPES,
                            Array.from(event.currentTarget.selectedOptions).map(
                                (option: HTMLOptionElement) => option.value
                            )
                        )
                    )
                }
            >
                <option
                    selected={selectedFaceShapes.includes(DIAMOND)}
                    value={DIAMOND}
                >
                    {DIAMOND}
                </option>
                <option
                    selected={selectedFaceShapes.includes(TRIANGLE)}
                    value={TRIANGLE}
                >
                    {TRIANGLE}
                </option>
                <option
                    selected={selectedFaceShapes.includes(SQUARE)}
                    value={SQUARE}
                >
                    {SQUARE}
                </option>
                <option
                    selected={selectedFaceShapes.includes(RECTANGLE)}
                    value={RECTANGLE}
                >
                    {RECTANGLE}
                </option>
                <option
                    selected={selectedFaceShapes.includes(OBLONG)}
                    value={OBLONG}
                >
                    {OBLONG}
                </option>
                <option
                    selected={selectedFaceShapes.includes(HEART)}
                    value={HEART}
                >
                    {HEART}
                </option>
                <option
                    selected={selectedFaceShapes.includes(OVAL)}
                    value={OVAL}
                >
                    {OVAL}
                </option>
                <option
                    selected={selectedFaceShapes.includes(ROUND)}
                    value={ROUND}
                >
                    {ROUND}
                </option>
                <option
                    selected={selectedFaceShapes.includes(PEAR)}
                    value={PEAR}
                >
                    {PEAR}
                </option>
                <option
                    selected={selectedFaceShapes.includes(SOFT_SQUARE)}
                    value={SOFT_SQUARE}
                >
                    {SOFT_SQUARE}
                </option>
            </Select>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                }}
            >
                {selectedFaceShapes.map((faceShape: string, index: number) => [
                    <span>{faceShape}</span>,
                    index + 1 !== selectedFaceShapes.length && <>{'–'}</>,
                ])}
            </div>

            <h6>{'jaw angle'}</h6>
            <Select
                onInput={(event: JSX.TargetedEvent<HTMLSelectElement>) =>
                    dispatch(
                        actions.set(
                            SELECTED_JAW_ANGLE,
                            event.currentTarget.value
                        )
                    )
                }
            >
                <option selected={true} disabled={true}></option>
                <option selected={selectedJawAngle === SOFT} value={SOFT}>
                    {SOFT}
                </option>
                <option selected={selectedJawAngle === MEDIUM} value={MEDIUM}>
                    {MEDIUM}
                </option>
                <option selected={selectedJawAngle === ACUTE} value={ACUTE}>
                    {ACUTE}
                </option>
            </Select>

            <h6>{'neck length'}</h6>
            <Select
                onInput={(event: JSX.TargetedEvent<HTMLSelectElement>) =>
                    dispatch(
                        actions.set(
                            SELECTED_NECK_LENGTH,
                            event.currentTarget.value
                        )
                    )
                }
            >
                <option selected={true} disabled={true}></option>
                <option selected={selectedNeckLength === SHORT} value={SHORT}>
                    {SHORT}
                </option>
                <option selected={selectedNeckLength === MEDIUM} value={MEDIUM}>
                    {MEDIUM}
                </option>
                <option selected={selectedNeckLength === LONG} value={LONG}>
                    {LONG}
                </option>
            </Select>
        </>
    );
}
