import { StoreProvider } from '../state/store-provider';
import { AppRouter } from './app-router';
import { JSX } from 'preact';
import { NavigationBar } from './navigation-bar';

export function App(): JSX.Element {
    return (
        <div style={style}>
            <StoreProvider>
                <NavigationBar>
                    <AppRouter />
                </NavigationBar>
            </StoreProvider>
        </div>
    );
}

const style = {
    height: '100%',
};
